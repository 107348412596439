import { getConfig } from '../../shared/config/config';

let fbProvider;
if (typeof document === 'undefined') {
  fbProvider = require('./firebaseProviderApp')
} else {
  fbProvider = require('./firebaseProviderWeb')
}

const database = fbProvider.database;
const storage = fbProvider.storage;
const messaging = fbProvider.messaging;
const auth = fbProvider.auth;
const { remoteConfig } = fbProvider || {}

export {
  database,
  storage,
  messaging,
  auth,
  remoteConfig
};
