import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import user from '../../../shared/reducers/userReducer';
import master from '../../../shared/reducers/masterReducer';

export const finalReducer = combineReducers({
  user,
  master,
});

const DEBUG = process.env.NODE_ENV === 'development';
const composeEnhancers =
  DEBUG &&
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const middleWare = [thunk, DEBUG && logger].filter(Boolean);

const enhancer = composeEnhancers(applyMiddleware(...middleWare));

export const createNewStore = () => createStore(finalReducer, {}, enhancer);
