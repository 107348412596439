import { firebase } from '@react-native-firebase/database';
import storage from '@react-native-firebase/storage';
import messaging from '@react-native-firebase/messaging'
import auth from '@react-native-firebase/auth';
import remoteConfig from '@react-native-firebase/remote-config';
import { getConfig } from '../config/config';
const config = getConfig();
const database = () => firebase.app().database(config.firebaseDatabase);

export {
  database,
  storage,
  messaging,
  auth,
  remoteConfig
};
