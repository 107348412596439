export default {
  apiKey: "AIzaSyB0xcNz98oASs63aaFeIcyyuj0mRZrNIrM",
  authDomain: "preprod-438eb.firebaseapp.com",
  databaseURL: "https://preprod-438eb-default-rtdb.firebaseio.com",
  projectId: "preprod-438eb",
  storageBucket: "preprod-438eb.appspot.com",
  messagingSenderId: "509885373470",
  appId: "1:509885373470:web:c33c54b9b09a8a387fa20f",
  measurementId: "G-ELMNENKNDY",
};
