import clevertap from 'clevertap-web-sdk';
import {getConfig} from '../../../shared/config/config';

// for enabling console debugging
sessionStorage.WZRK_D = '';

const config = getConfig();
const cleverTapProjectToken = config.cleverTapId;

let cleverTapTrackEvent = () => {};
let cleverTapOnUserLogin = () => {};
let cleverTapInit = () => {};
let cleverTapClear = () => {};

// for enabling console debugging
sessionStorage.WZRK_D = '';
cleverTapInit = () => {
  clevertap.privacy.push({optOut: false});
  clevertap.privacy.push({useIP: false});
  clevertap.init(cleverTapProjectToken);
  clevertap.spa = true;
};
cleverTapClear = () => {
  return clevertap.clear();
};
cleverTapTrackEvent = (eventName, attrObj = null) => {
  if (attrObj == null) {
    return clevertap.event.push(eventName);
  }
  return clevertap.event.push(eventName, attrObj);
};
cleverTapOnUserLogin = attrObj => {
  return clevertap.onUserLogin.push({
    Site: attrObj,
  });
};

export {
  cleverTapTrackEvent,
  cleverTapOnUserLogin,
  cleverTapInit,
  cleverTapClear,
};
