import Bugsnag from '@bugsnag/react-native';
import { Platform } from 'react-native';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { getAppVersion } from '../services/appVersionService';
import {
  getConfig,
} from '../config/config';

const config = getConfig();

export async function bugsnagStart() {
  if (process.env.NODE_ENV === 'development') return;

  // different initialisation for web and apps
  if(Platform.OS === 'web') {
    Bugsnag.start({
      apiKey: config.bugSnagWeb,
      plugins: [new BugsnagPluginReact()],
    });
  } else {
    const appVersion = await getAppVersion();
    Bugsnag.start({
      codeBundleId: '',
      metadata: {
        version: appVersion,
      },
    });
  }
  
}
