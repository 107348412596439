import React from 'react';
import {
  Keyboard,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {events} from '../../../../../shared/constants/analyticsEvents';
import If from '../../common/If/If';
import '../Checkups.scss';
import ReportIcon from '../../../static/images/report_time_icon.png';
import GroupTestIcon from '../../../static/images/groupTestIcon.png';
import FastIcon from '../../../static/images/fast.png';
import Arrow from '../../../static/images/arrow.png';

function RenderCheckupItem(props) {
  const {
    selectedCheckups,
    checkup,
    calculatePercent,
    calculatePackageTAT,
    updateSelectedCheckups,
    MixpanelService,
    cleverTapTrackEvent,
    renderGroupTests,
    searchTerm,
    CheckupTestModal,
  } = props;

  const isSelected = selectedCheckups.findIndex(t => t.id === checkup?.id) > -1;
  const percentage = calculatePercent(
    checkup?.totalTestsPrice,
    checkup?.consumerPrice,
  );

  const duration = calculatePackageTAT(
    checkup?.testsMetadata,
    checkup?.logisticTat,
  );
  const {numberOfParameters} = checkup || {};

  return (
    <View style={styles.test}>
      <View style={styles.selection}>
        <div key="name" className="testText">
          {checkup?.packageName}
          <If
            condition={numberOfParameters && numberOfParameters !== 0}
            render={() => (
              <div key="param" className="parameters">
                {numberOfParameters} parameters
              </div>
            )}
          />
        </div>

        <TouchableWithoutFeedback
          onPress={() => {
            updateSelectedCheckups(prev => {
              const newSelection = [...prev];
              const seletedIndex = newSelection.findIndex(
                params => params.id === checkup?.id,
              );
              if (seletedIndex > -1) {
                MixpanelService.track('Uncheck Selected Checkup', {
                  remove_selected_checkup:
                    newSelection?.[seletedIndex]?.packageName,
                  checkups_count: prev.length - 1,
                });
                newSelection.splice(seletedIndex, 1);
              } else {
                MixpanelService.track('Checkup Selected', {
                  selected_checkup: checkup?.packageName,
                  checkups_count: prev.length + 1,
                });
                cleverTapTrackEvent(events.CHECKUP_SELECTED, {
                  selected_checkup: checkup?.packageName,
                  checkups_count: prev.length + 1,
                });
                newSelection.push({
                  ...checkup,
                  isFastingRequired: checkup?.testsMetadata?.some(
                    testItem => testItem?.isFastingRequired === 1,
                  ),
                  testsMetadata: undefined,
                  packageInfo: undefined,
                });
              }
              return newSelection;
            });
            Keyboard.dismiss();
          }}>
          <div>
            <If
              key="selected"
              condition={isSelected}
              render={() => (
                <div className="addedButton">
                  <div className="addedText">Added</div>
                </div>
              )}
            />
            <If
              key="default"
              condition={!isSelected}
              render={() => (
                <div className="addButton">
                  <div className="addText">+ Add</div>
                </div>
              )}
            />
          </div>
        </TouchableWithoutFeedback>
      </View>

      <div className={percentage !== 0 ? 'testData' : 'textRowPrice'}>
        <If
          condition={percentage !== 0}
          render={() => (
            <div className="orangeTag">
              <span className="orangeText">{percentage}% off</span>{' '}
            </div>
          )}
        />

        <div className="textRowPrice">
          <If
            condition={percentage !== 0}
            render={() => (
              <div className="testPriceContainer">
                <div className="rupee2">&#8377;</div>
                <div key="original-total" className="amount2">
                  {checkup?.totalTestsPrice}&nbsp;
                </div>
              </div>
            )}
          />

          <div className="testPriceContainer">
            <div key="price" className="rupee">
              &#8377;&nbsp;
            </div>
            <div key="amount" className="amount">
              {checkup?.consumerPrice}
            </div>
          </div>
        </div>
      </div>

      <div className="divider" />
      <If
        condition={duration}
        render={() => (
          <div className="spacing">
            <div key="category" className="reports">
              <img
                src={ReportIcon}
                alt="report icon"
                className="reportTimeIcon"
              />
              Reports within {duration}
            </div>
          </div>
        )}
      />
      <If
        condition={checkup?.testsMetadata?.find(
          testItem => testItem?.isFastingRequired === 1,
        )}
        render={i => (
          <div key="category" className="fasting">
            <img src={FastIcon} className="arrow" />
            <div key={i} className="fastText">
              10 - 12 hours of fasting required
            </div>
          </div>
        )}
      />
      <If
        condition={checkup?.testsMetadata && checkup?.testsMetadata.length}
        render={() => (
          <div
            className={`spacing ${
              checkup?.testsMetadata?.length > 3 && 'showPointer'
            }`}
            onClick={() =>
              checkup?.testsMetadata?.length > 3 &&
              CheckupTestModal(checkup?.testsMetadata, checkup?.packageName)
            }>
            <div className="row">
              <div className="col-1">
                <img src={GroupTestIcon} className="arrow" />
              </div>
              <div className="col-2">
                <span className="groupTestsList">
                  {checkup?.testsMetadata?.length} test
                  {checkup?.testsMetadata?.length === 1 ? '' : 's'}:
                </span>
                {renderGroupTests(checkup.testsMetadata, searchTerm)}
                <If
                  condition={checkup?.testsMetadata?.length > 3}
                  render={() => (
                    <span className="groupTestText">
                      and {checkup?.testsMetadata?.length - 3} other test
                      {checkup?.testsMetadata?.length - 3 === 1 ? '' : 's'}
                      {'   '}
                    </span>
                  )}
                />
              </div>
            </div>
            <div className="viewAllTestLayout">
              <If
                condition={checkup?.testsMetadata?.length > 3}
                render={() => (
                  <div
                    onClick={() =>
                      CheckupTestModal(
                        checkup?.testsMetadata,
                        checkup?.packageName,
                      )
                    }
                    className="viewAllTestsContainer">
                    <div className="viewAllTests">View all tests</div>

                    <img src={Arrow} alt="arrow" className="testArrow" />
                  </div>
                )}
              />
            </div>
          </div>
        )}
      />
    </View>
  );
}

export default RenderCheckupItem;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#fff6ea',
    opacity: 1,
    backgroundSize: '7px 7px',
    backgroundImage:
      'repeating-linear-gradient(45deg, rgb(255 255 255 / 54%) 0px, rgb(142 142 142 / 26%) 0.7px, rgb(255, 246, 234) 0px, rgb(255, 246, 234) 50%)',
  },
  scrollContent: {
    backgroundColor: '#fff',
    width: '100%',
    minHeight: '100vh',
  },
  header: {
    paddingHorizontal: 25,
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerUpper: {
    backgroundColor: '#eceee6',
    paddingBottom: '47px',
  },
  heading: {
    color: '#000',
    fontSize: 14,
    marginBottom: 3,
    fontWeight: 'bold',
    marginLeft: 12,
    textTransform: 'uppercase',
  },
  addressPin: {
    width: 11,
    height: 11,
    marginRight: 2,
    marginLeft: 12,
  },
  subHeadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subHeadingTextContainer: {
    flexDirection: 'row',
  },
  subHeadingGreenText: {
    fontSize: 10,
    fontWeight: '600',
    color: '#01b37a',
    textTransform: 'uppercase',
    fontFamily: 'montserrat-semibold',
  },
  subHeadingGreyText: {
    fontSize: 10,
    fontWeight: '600',
    color: '#7d839a',
    textTransform: 'uppercase',
  },
  backButton: {
    width: 44,
    height: 44,
    marginTop: 5,
  },
  searchContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    marginTop: 20,
  },
  searchIconWrapper: {
    position: 'absolute',
    right: '10%',
    elevation: 5,
    zIndex: 5,
  },
  searchIcon: {
    height: 18,
    width: 18,
  },
  search: {
    color: '#616161',
    height: 55,
    paddingRight: '12%',
    width: '90%',
    borderColor: '#fff6ea',
    borderWidth: 1,
    borderRadius: 8,
  },
  tests: {
    paddingBottom: 50,
    paddingTop: 15,
  },
  test: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: '#fff',
    minHeight: 80,
    marginHorizontal: 20,
    marginBottom: 16,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },
  testMeta: {
    flex: 1,
    marginTop: 6,
    marginBottom: 16,
  },
  testText: {
    color: '#1c1c1c',
    fontSize: 14,
    fontWeight: 'bold',
    minWidth: '80%',
  },
  rupee: {
    color: '#7d839a',
    fontSize: 12,
    fontWeight: '500',
  },
  price: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#53596c',
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {height: 300},
  paginationLoaderWrapper: {alignItems: 'center'},

  orderBtnWrapper: {
    height: 65,
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 15,
    width: '88%',
    maxWidth: 460,
    position: 'absolute',
    bottom: 0,
  },
  checkBoxIcon: {
    width: 24,
    height: 24,
    marginLeft: 14,
  },
  selection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  testName: {
    fontSize: 12,
    color: '#53596c',
    marginTop: 8,
    overflow: 'visible',
    fontFamily: 'montserrat-medium',
  },
  testPrice: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  rupee2: {
    fontSize: 9,
    color: '#9d9d9d',
    marginLeft: 5,
  },
  totalPrice: {
    fontSize: 12,
    color: '#9d9d9d',
    textDecorationLine: 'line-through',
  },
  discount: {
    color: '#616161',
    fontSize: 10,
    marginLeft: 5,
  },
  testData: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 16,
  },
  category: {
    color: '#7d839a',
    fontSize: 10,
  },
  categoryText: {
    color: '#7d839a',
    fontSize: 10,
    fontFamily: 'montserrat-bold',
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tag: {
    paddingVertical: 3,
    paddingHorizontal: 6,
    flexDirection: 'row',
    marginTop: 10,
    alignSelf: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#f6f0fd',
    borderRadius: 3,
  },
  tagText: {
    fontSize: 9,
    fontWeight: 'bold',
    color: '#750ea3',
    marginRight: 2,
  },
  tagIcon: {
    width: 10,
    height: 10,
    marginTop: 1,
  },
  cantFindTestsCard: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#ffe8ca',
    backgroundColor: '#fff',
    width: '85%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  cantFindTestsCardTitle: {
    fontSize: 14,
    lineHeight: 18,
    textAlign: 'center',
    color: '#3c3c3c',
    marginBottom: 10,
    fontFamily: 'montserrat-bold',
  },
  cantFindTestsCardSubtext: {
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 16,
    textAlign: 'center',
    color: '#616161',
    marginBottom: 10,
  },
  cantFindTestsCTA: {
    paddingHorizontal: 34,
    paddingVertical: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ff970a',
    backgroundColor: '#fff',
    flex: 1,
  },
  cantFindTestsCTAText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#ff970a',
  },
  notFound: {
    marginVertical: 30,
    flexDirection: 'column',
    alignItems: 'center',
    width: '70%',
  },
  notFoundTitle: {
    fontSize: 14,
    color: '#616161',
    marginBottom: 10,
    fontFamily: 'montserrat-bold',
  },
  notFoundSubTitle: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'center',
    color: '#616161',
  },
  bookButtonWrapper: {
    width: '100%',
    height: 70,
    alignSelf: 'center',
    marginVertical: 24,
  },
  bookButtonContentWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  bookButtonAnimation: {
    width: 72,
    height: 72,
  },
  bookButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'montserrat-bold',
    paddingLeft: 11,
  },
  bookButtonSubText: {
    color: '#fff',
    fontSize: 14,
    paddingLeft: 11,
  },
});
