export default function useQuery(queryString, searchParam) {
  return new URLSearchParams(queryString).get(searchParam);
}

export function useQueryAll(queryString, searchParam) {
  return new URLSearchParams(queryString).getAll(searchParam);
}

export const getQueryParams = (queryString, searchParam) => {
  return new URLSearchParams(queryString).get(searchParam);
}

export const deleteQueryParams = (queryString, history, searchParamToDelete) => {
  const params = new URLSearchParams(queryString);
  params.delete(searchParamToDelete);
  history.replace({
    search: params.toString(),
  });
}

export const deleteAllQueryParams = (history) => {
  history.replace({
    search: '',
  });
}
