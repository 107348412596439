import { Platform } from 'react-native';
export default function semiBoldFontConverter(fontWeight = '600') {
  const WEIGHT_CONVERSION_IOS_ANDROID = {
    600: 'Montserrat-SemiBold',
  };
  return {
    ...Platform.select({
      ios: {
        fontWeight: fontWeight.toString(),
      },
      android: {
        fontFamily: WEIGHT_CONVERSION_IOS_ANDROID[fontWeight.toString()],
      },
      web: {
        fontWeight: fontWeight.toString(),
      },
    }),
  };
}
