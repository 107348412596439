import React from 'react';

import AppText from '../AppText/AppText.native';

const AppError = ({ style, children, errPatientBoxStyle=null, ...rest }) => {
  return(<AppText style={errPatientBoxStyle ? errPatientBoxStyle : { ...styles.error, ...style }} {...rest}>{children}</AppText>);
};

const styles = {
  error: {
    color: '#ec2637',
    fontSize: 12,
  }
};

export default AppError;
