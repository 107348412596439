export default function elevationShadowStyle(elevation) {
  return {
    elevation,
    shadowOffset: {
      width: 0,
      height: 0.5 * elevation,
    },
    shadowOpacity: 0.2,
    shadowRadius: 0.8 * elevation,
  };
}
