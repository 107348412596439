import React, { Component } from 'react';
import { Platform, Text } from 'react-native';
import './AppTextStyle.scss';

const styles = {
  text: {
    fontFamily: 'montserrat-medium',
    fontWeight: Platform.OS == 'ios' ? '500' : 'normal',
  },
  boldText: {
    fontFamily: 'montserrat-bold',
    fontWeight: Platform.OS == 'ios' ? '700' : 'normal', // As IOS is not supporting default font family
  },
  overflowVisible: {
    overflow: 'visible',
  },
};

export default class AppText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerStyles: props.containerStyles,
    };
    this.style = [styles.text];
    if (Array.isArray(props.style)) {
      this.style = this.style.concat(props.style);
    } else {
      this.style.push(props.style);
    }
    const addedStyle = { ...(props.style || {}) };
    if (addedStyle.fontWeight === 'bold') {
      this.style.push(styles.boldText);
    }
    this.className = this.props.className
      ? `app-text ${this.props.className}`
      : 'app-text';
  }

  render() {
    const containerStyle = {
      ...(this.props.contentOverflows ? styles.overflowVisible : {}),
      ...(this.props.containerStyle ? this.props.containerStyle : {}),
      ...(this.state.containerStyles ? this.state.containerStyles : {}),
    };

    return (
      <div className={this.className} style={containerStyle}>
        <Text {...this.props} style={this.style}>
          {this.props.children}
        </Text>
      </div>
    );
  }
}
