import mixpanel from 'mixpanel-browser';
import {getConfig} from '../../../shared/config/config';

const config = getConfig();
const mixpanelProjectToken = config.mixpanelWebAppProjectToken;

mixpanel.init(mixpanelProjectToken);

const actions = {
  identify: id => {
    mixpanel.identify(id);
  },
  alias: id => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: props => {
      mixpanel.people.set(props);
    },
  },
};

export const MixpanelService = actions;
