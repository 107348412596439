import throttle from 'lodash.throttle';
import { saveState } from '../helpers/localstorage';

let store;
export default (createNewStore, initialState) => {
  try {
    if (createNewStore) {
      store = createNewStore(initialState);
      store.subscribe(throttle(() => {
        saveState({
          ...store.getState()
        });
      }, 1000));
    return store;
    }
  } catch {
    return store
  }
}
