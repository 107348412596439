import jwtDecode from "jwt-decode";

import { getConfig } from "../config/config";
import {
  getItem,
  REFRESH_TOKEN,
  AUTH_TOKEN,
  setItem,
  clearLocalStorage,
} from "./localstorage";
import { refreshTokenAction } from "../actions/authActions";
import { refreshFirebaseToken } from "../services/firebaseService";
import { Platform } from "react-native";
import { getCookieValue, setCookie } from "./authhelpers";

export async function getAuthToken() {
  const token = await getItem(AUTH_TOKEN);
  return token;
}

export async function validateAndRefreshToken(dispatch) {
  let token = await getAuthToken();
  if (!token) return null;

  const decoded = jwtDecode(token);

  const { exp } = decoded;
  const timeStamp = Math.floor(Date.now() / 1000);
  const diff = exp - timeStamp;
  if (diff < 300) {
    token = await refreshToken(dispatch);
  }
  return token;
}

export function getHeadersWithoutToken() {
  const { appName, appVersion } = getConfig();

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "User-Agent": `${appName}/${Platform.OS}/${appVersion}`,
  };
}

export function getHeadersWithToken(token) {
  const { appName, appVersion } = getConfig();
  return {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "application/json",
    "User-Agent": `${appName}/${Platform.OS}/${appVersion}`,
  };
}

export default async function authHeader(headersObject) {
  const token = await validateAndRefreshToken();
  if (token) {
    const headers = getHeadersWithToken(token);
    if (headersObject === false) {
      return headers;
    } else {
      return new Headers(headers);
    }
  }
  return {};
}

export async function publicHeader(headersObject) {
  const headers = getHeadersWithoutToken();
  if (headersObject === false) {
    return headers;
  } else {
    return new Headers(headers);
  }
}

export async function refreshToken(dispatch) {
  let token;
  const localRefreshToken = await getItem(REFRESH_TOKEN);
  const refreshToken = getCookieValue("refresh_token") || localRefreshToken;
  const refresh = {
    refresh: refreshToken,
  };
  let res = await fetch(`${getConfig().authHost}/user/refresh/token/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(refresh),
  });
  if (res.status !== 200) {
    await clearLocalStorage();
  } else {
    const tokens = await res.json();
    await setItem(AUTH_TOKEN, tokens.access);
    await setItem(REFRESH_TOKEN, tokens.refresh);
    setCookie("access_token", tokens.access, 1);
    setCookie("refresh_token", tokens.refresh, 360);
    if (dispatch) {
      dispatch(refreshTokenAction());
    } else {
      await refreshFirebaseToken();
    }
    token = tokens.access;
  }
  return token;
}
