import * as React from 'react';
import {
  StyleSheet,
  View,
  Image,
  SafeAreaView,
  TouchableOpacity,
  Keyboard,
  useWindowDimensions,
  ScrollView,
  Dimensions,
  Pressable,
} from 'react-native';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import LottieView from 'lottie-react-native';
import Modal from 'react-modal';

import If from '../../common/If/If';
import SubmitButton from '../../common/SubmitButton/SubmitButton';
import calculatePercent from '../../../helpers/calculatePercent';

import AppText from '../../../../../shared/components/common/AppText/AppText';
import {events} from '../../../../../shared/constants/analyticsEvents';

import {MixpanelService} from '../../../services/MixpanelService';
import {cleverTapTrackEvent} from '../../../services/ClevertapService';
import {setItem, getItem} from '../../../../../shared/helpers/localstorage';
import {
  getAllPartnerCheckups,
  getAllB2cPartnerTests,
  getUnifiedTestsCheckups,
  getPartnerTestsAndCheckups,
} from '../../../../../shared/services/publicServices';

import frequentItemsOnTop from '../../../../../shared/helpers/frequentItemsOnTop';
import useQuery from '../../../../../shared/hooks/useQuery';
import {TestParameters} from '../../../../../shared/constants/common';

import {getAllCities} from '../../../../../shared/services/publicServices';

import '../Checkups.scss';
import '../Tests.scss';
import RenderTestItem from './RenderTestItem';
import RenderCheckupItem from './RenderCheckupItem';
import redirectToWhatsapp from '../../../../../shared/helpers/redirectToWhatsapp';
import UnifiedSearchList from './UnifiedSearchList';

export default function UnifiedSearch() {
  const {tab: activeTab, patientId} = useParams();
  const {width, height} = useWindowDimensions();
  const [selectedCheckups, updateSelectedCheckups] = React.useState([]);
  const [selectedTests, updateSelectedTests] = React.useState([]);

  const [searchTerm, updateSearchTerm] = React.useState('');
  const [patientListState, updatePatientListState] = React.useState([]);
  const [city, updateCity] = React.useState(null);
  const [cities, updateCities] = React.useState(null);
  const [list, updateList] = React.useState([{tests: [], packages: []}]);
  const [frequentCheckups, updateFrequentCheckups] = React.useState([]);
  const [frequentTests, updateFrequentTests] = React.useState([]);

  const [showGroupTestModal, updateShowGroupTestsModal] = React.useState();
  const [packageName, setPackageName] = React.useState('');

  const history = useHistory();
  const location = useLocation();

  const partnerId = useQuery(location.search, 'partnerId');

  const altCityNames = {
    MVS: 'Mumbai',
    NOA: 'Delhi - NCR',
    NDM: 'Delhi - NCR',
    DEL: 'Delhi - NCR',
  };

  const partnerIdfromHistory = history.location.state?.partnerId;
  const {isUnifiedPartner} = history?.location?.state || false;

  const rootStyles = {
    flex: 1,
    maxWidth: 500,
    minWidth: width > 500 ? 500 : '100%',
    minHeight: height,
    position: 'relative',
    backgroundColor: '#f6f7f2',
  };
  const modalContentStyles = {
    padding: 24,
    border: 'none',
    borderRadius: 24,
    backgroundColor: '#fff',
    maxWidth: 310,
    margin: 'auto',
    height: 'min-content',
  };
  const searchContainerStyle = {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 12,
    marginTop: '-29px',
  };

  const timeStamp = location.pathname.split('/');

  const patientList = [
    {
      createdAt: timeStamp[timeStamp.length - 1],
      name: '',
      age: '',
      gender: '',
      isAddingPrescription: false,
      test: '',
      isOpen: true,
      tests: [],
      checkups: [],
      errors: {
        name: '',
        age: '',
        gender: '',
        test: '',
      },
    },
  ];

  React.useEffect(() => {
    const fetchCities = async () => {
      try {
        const res = await getAllCities();
        const allCities = res.cities.filter(city => city.live);
        updateCities([...allCities]);
      } catch {
        updateCities([]);
      }
    };
    fetchCities();
  }, []);

  const resetHistoryObj = () => {
    history.replace({
      pathname: history.location.state?.from || '/public/order',
      search: history.location.state?.search,
    });
  };

  React.useEffect(() => {
    const getCitycode = async () => {
      const queryParams = new URLSearchParams(location.search);
      let cityParam = queryParams.get('city');
      if (cityParam === 'NOA' || cityParam === 'NDM') {
        cityParam = 'DEL';
      }
      if (cityParam === 'MVS') {
        cityParam = 'MUM';
      }
      const cityData = await getItem('city');
      if (cityData !== null && !cityParam) {
        if (cityData && cityData?.city_code) {
          updateCity(cityData);
        } else {
          resetHistoryObj();
        }
      } else {
        if (getItem('patientList')) {
          setItem('patientList', {patientList});
        }
        if (cities && cityParam) {
          const cityData = cities.find(each => {
            return each?.city_code === cityParam;
          });
          if (cityData) {
            setItem('city', cityData);
            updateCity(cityData);
          } else {
            resetHistoryObj();
          }
        }
      }
    };
    getCitycode();
  }, [history, cities]);

  React.useEffect(() => {
    async function getPatientList() {
      const patients = await getItem('patientList');
      if (patients && Object.keys(patients).length > 0) {
        updateSelectedCheckups(
          () => patients?.patientList?.find(item => item?.isOpen).checkups,
        );
        updateSelectedTests(
          patients?.patientList?.find(item => item?.isOpen).tests,
        );
        updatePatientListState(patients);
      } else {
        setItem('patientList', {patientList});
      }
    }
    const getFrequentTests = async () => {
      const res = await getItem('frequentTests');
      if (res?.length > 0) {
        updateFrequentTests(res);
      }
    };
    const getFrequentPackages = async () => {
      const res = await getItem('frequentPackages');
      if (res?.length > 0) {
        updateFrequentCheckups(res);
      }
    };
    getPatientList();
    getFrequentTests();
    getFrequentPackages();
  }, [cities]);

  const shouldShowCheckupsAddButton = React.useCallback(() => {
    return selectedCheckups?.length > 0;
  }, [selectedCheckups]);

  const shouldShowTestsAddButton = React.useCallback(() => {
    return selectedTests?.length > 0;
  }, [selectedTests]);

  const onPressSelect = test => {
    updateSelectedTests(prev => {
      const newSelection = [...prev];
      const seletedIndex = newSelection.findIndex(t => t.id === test.id);
      if (seletedIndex > -1) {
        MixpanelService.track('Uncheck Selected Test', {
          remove_selected_test: newSelection?.[seletedIndex]?.testName,
          tests_count: prev.length - 1,
        });
        newSelection.splice(seletedIndex, 1);
      } else {
        MixpanelService.track('Test Selected', {
          selected_test: test?.testName,
          tests_count: prev.length + 1,
        });
        cleverTapTrackEvent(events.TEST_SELECTED, {
          selected_test: test?.testName,
          tests_count: prev.length + 1,
        });
        newSelection.push({
          ...test,
          isFastingRequired: test?.isFastingRequired === 1 ? true : false,
          testInfo: undefined,
        });
      }
      return newSelection;
    });
    Keyboard.dismiss();
  };

  const updateTestsCheckups = React.useCallback(
    async (keyToUpdate, data) => {
      const patientToUpdate = patientListState.patientList.findIndex(
        patient => patient?.isOpen,
      );
      const newPatients = patientListState.patientList.map(
        (patient, patientIndex) => {
          if (patientIndex === patientToUpdate) {
            if (keyToUpdate === 'test') {
              return {
                ...patient,
                ...data,
                shouldFocus: true,
                errors: {
                  ...patient.errors,
                  test: '',
                },
              };
            }
            return {
              ...patient,
              ...data,
              errors: {
                ...patient.errors,
                test: '',
              },
            };
          }
          return patient;
        },
      );
      await setItem('patientList', {
        ...patientListState,
        patientList: newPatients,
      });
    },
    [patientListState],
  );

  React.useEffect(() => {
    if (patientListState?.patientList?.length > 0) {
      updateTestsCheckups('', {
        checkups: selectedCheckups,
        tests: selectedTests,
      });
    }
  }, [selectedCheckups, patientListState, updateTestsCheckups, selectedTests]);

  const fetchTestData = React.useCallback(
    async ({search, abortController}) => {
      let result = await getUnifiedTestsCheckups({
        cityCode: city?.city_code,
        search,
        abortController,
        limit: 50,
      });

      if (!result[activeTab]?.length) {
        MixpanelService.track(`Searched ${activeTab} query not found`, {
          search_string: search,
        });
      }
      if (
        activeTab === 'packages' &&
        frequentCheckups?.length &&
        result?.length &&
        search
      ) {
        let frequentItems = result[activeTab];
        return frequentItemsOnTop([...frequentCheckups], [...frequentItems]);
      }
      return result;
    },
    [city?.city_code, frequentCheckups],
  );

  const fetchPartnerTestAndCheckupsData = React.useCallback(
    async ({search, abortController}) => {
      let result = await getPartnerTestsAndCheckups({
        cityCode: city?.city_code,
        search,
        abortController,
        limit: 50,
        partnerId: partnerIdfromHistory || partnerId,
      });

      if (!result[activeTab]?.length) {
        MixpanelService.track(`Searched ${activeTab} query not found`, {
          search_string: search,
        });
      }
      if (
        activeTab === 'packages' &&
        frequentCheckups?.length &&
        result?.length &&
        search
      ) {
        let frequentItems = result[activeTab];
        return frequentItemsOnTop([...frequentCheckups], [...frequentItems]);
      }
      return result;
    },
    [city?.city_code, frequentCheckups],
  );

  const fetchPartnerCheckupsData = React.useCallback(
    async ({search, abortController}) => {
      //refactor
      var checkups = await getAllPartnerCheckups({
        cityCode: city?.city_code,
        search,
        abortController,
        limit: 500,
      });
      checkups = checkups.filter(function (item) {
        item.testsMetadata = JSON.parse(item?.testsMetadata);
        return item?.partnerId === Number(partnerId) && item?.status === 1;
      });
      return checkups;
    },
    [city, partnerId],
  );

  const fetchB2cTestData = React.useCallback(
    async ({search, abortController}) => {
      const {city_code} = city;
      const tests = await getAllB2cPartnerTests({
        partnerId: partnerId,
        search,
        abortController,
        limit: TestParameters.LIMIT,
        keyToGet: TestParameters.TEST,
        cityCode: city_code,
      });
      return tests;
    },
    [partnerId, city],
  );

  const renderGroupTests = (tests, searchTerm) => {
    if (activeTab === 'tests') {
      const index = tests.findIndex(test =>
        test.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      if (index > -1) {
        let cutOut = tests.splice(index, 1)[0];
        tests.splice(0, 0, cutOut);
      }

      const testsArr = [];
      tests.forEach((test, index) => {
        if (index < 3) {
          testsArr.push((index ? ', ' : ' ') + test);
        }
      });
      return testsArr.map((item, index) => (
        <span key={`${item}-${index}`} className="groupTestText">
          {item}{' '}
        </span>
      ));
    } else {
      const testArray = [];
      const testsArr = [];
      if (activeTab === 'packages') {
        tests.forEach((test, i) => {
          testArray.push(test.testName);
        });
      }
      const index = testArray.findIndex(test =>
        test.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      if (index > -1) {
        let cutOut = tests.splice(index, 1)[0];
        tests.splice(0, 0, cutOut);
      }

      tests.forEach((test, index) => {
        if (index < 3) {
          testsArr.push((index ? ', ' : ' ') + test.testName);
        }
      });
      return testsArr.map((item, index) => (
        <span key={`${item}-${index}`} className="groupTestText">
          {item}{' '}
        </span>
      ));
    }
  };

  const showTATTime = tatInHours => {
    if (!tatInHours) {
      return;
    }
    let tatTime = tatInHours;
    if (tatInHours > 24) {
      tatTime = Math.ceil(tatInHours / 24);
      return `${tatTime} ${tatTime > 1 ? 'days' : 'day'}`;
    }
    return `${tatTime} hours`;
  };

  const renderTAT = labTat => {
    if (!labTat) {
      return;
    }
    let tatTime = labTat;
    if (labTat > 24) {
      tatTime = Math.ceil(labTat / 24);
      return `${tatTime} ${tatTime > 1 ? 'days' : 'day'}`;
    }
    return `${tatTime} hours`;
  };

  const handleTabs = tab => {
    MixpanelService.track(
      `Switched to ${tab === 'packages' ? 'Checkup Search' : 'Test Search'}`,
    );
    const redirectURL = `/public/add-tests-checkups/${tab}/${patientId}`;
    history.replace({
      pathname: redirectURL,
      state: {
        ...history.location.state,
      },
    });
  };

  const calculatePackageTAT = (testsList, logisticTat) => {
    const testWithMaxTAT = testsList?.reduce(
      (prev, curr) => (prev?.labTat > curr?.labTat ? prev : curr),
      0,
    );
    return showTATTime(
      parseInt(testWithMaxTAT?.labTat || 0, 10) +
        parseInt(logisticTat || 0, 10),
    );
  };

  const CantFindSearchResults = () => (
    <>
      <View style={styles.notFound}>
        <AppText style={styles.notFoundTitle} contentOverflows>
          Oops! No result found
        </AppText>
        <AppText style={styles.notFoundSubTitle} contentOverflows>
          Please try again or browse through our full list of tests
        </AppText>
      </View>
      <View style={styles.cantFindTestsCard}>
        <AppText style={styles.cantFindTestsCardTitle} contentOverflows>
          Can't find the test / checkup you are looking for?
        </AppText>
        <AppText style={styles.cantFindTestsCardSubtext} contentOverflows>
          Our support team can help you book the test / checkup you are looking
          for
        </AppText>
        <Pressable
          onPress={() => {
            let key = `search_${activeTab}`;
            MixpanelService.track('No search results - Contact support', {
              [key]: searchTerm,
            });
            redirectToWhatsapp(
              `Hi, help me finding tests/checkups for this search query "${searchTerm}"`,
            );
          }}
          accessible
          accessibilityLabel="go-back-to-add-tests">
          <View style={styles.cantFindTestsCTA}>
            <AppText style={styles.cantFindTestsCTAText} contentOverflows>
              Contact support
            </AppText>
            <Image
              source={require('../../../static/images/whatsapp.png')}
              style={styles.WAIcon}
            />
          </View>
        </Pressable>
      </View>
    </>
  );

  const CheckupTestModal = (testsList, testName) => {
    const testArray = [];

    testsList.forEach((test, i) => {
      testArray.push(test.testName);
    });
    updateShowGroupTestsModal(testArray);
    setPackageName(testName);
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView style={rootStyles}>
        <If
          condition={!!showGroupTestModal}
          render={() => (
            <Modal
              isOpen={!!showGroupTestModal}
              onRequestClose={() => updateShowGroupTestsModal(undefined)}
              onBackdropPress={() => updateShowGroupTestsModal(undefined)}
              onBackButtonPress={() => updateShowGroupTestsModal(undefined)}
              style={{
                content: modalContentStyles,
                overlay: {backgroundColor: '#0000009e'},
              }}>
              <>
                <div>
                  <div className="modalHeader">
                    <div className="modalTitle">
                      {packageName || showGroupTestModal.testName}
                    </div>
                    <div
                      className="closeBtnWrapper"
                      onClick={() => updateShowGroupTestsModal(undefined)}>
                      <img
                        src={require('../../../static/images/close-icon-circle.png')}
                        className="closeBtnIcon"
                      />
                    </div>
                  </div>
                  <div className="scroll">
                    {activeTab === 'tests' &&
                      showGroupTestModal?.groupTests?.map((x, id) => (
                        <div key={`${x}-${id}`} className="groupTestsList">
                          • {x}
                        </div>
                      ))}
                    {activeTab === 'packages' &&
                      showGroupTestModal?.map((x, id) => (
                        <div key={`${x}-${id}`} className="groupTestsList">
                          • {x}
                        </div>
                      ))}
                  </div>
                </div>
              </>
            </Modal>
          )}
        />
        <View style={styles.headerUpper}>
          <View style={styles.header}>
            <TouchableOpacity
              onPress={() => {
                MixpanelService.track('Search - go back');
                history.replace({
                  pathname: history.location.state?.from || '/public/order',
                  search: history.location.state?.search,
                });
              }}
              accessible
              accessibilityLabel="go-back">
              <Image
                style={styles.backButton}
                source={require('../../../static/images/back_icon.png')}
              />
            </TouchableOpacity>
            <View styles={activeTab === 'tests' && styles.headerText}>
              <AppText style={styles.heading}>{`Select ${
                activeTab === 'packages' ? 'Checkups' : 'Tests'
              }`}</AppText>
              <View style={styles.subHeadingContainer}>
                <Image
                  source={require('../../../static/images/address_pin.png')}
                  style={styles.addressPin}
                />
                <View style={styles.subHeadingTextContainer}>
                  <AppText style={styles.subHeadingGreenText}>{`${
                    Object.keys(altCityNames).indexOf(city?.city_code) !== -1
                      ? altCityNames[city?.city_code]
                      : city?.name || ''
                  }  `}</AppText>
                </View>
              </View>
            </View>
          </View>
          {/* <TrustMarkers /> */}
        </View>
        <If
          condition={city?.city_code}
          render={() => (
            <UnifiedSearchList
              handleTabs={handleTabs}
              activeTab={activeTab}
              hideTestList={activeTab === 'tests'}
              searchPlaceholder={
                partnerId
                  ? 'Search partner checkups'
                  : 'Search tests & checkups ...'
              }
              searchIcon={require('../../../static/images/search_icon_light.png')}
              noDataText={
                partnerId ? 'No partner checkups found' : 'No checkups found'
              }
              searchHeader={partnerId ? 'All Partner Checkups' : 'All Checkups'}
              primarySectionHeader={
                partnerId
                  ? 'All Partner Checkups'
                  : `All ${activeTab === 'tests' ? 'Tests' : 'Checkups'}`
              }
              secondarySectionHeader={`Frequently booked ${
                activeTab === 'tests' ? 'Tests' : 'Checkups'
              }`}
              frequentData={
                activeTab === 'tests' ? frequentTests : frequentCheckups
              }
              onSearch={query => {
                updateSearchTerm(query);
                if (query) {
                  MixpanelService.track(
                    `${
                      activeTab === 'tests' ? 'Tests' : 'Checkups'
                    } search - query`,
                    {
                      search_checkups: query,
                    },
                  );
                }
              }}
              children={<CantFindSearchResults />}
              style={
                selectedCheckups?.length > 0 || selectedTests?.length > 0
                  ? {
                      height: height - 180,
                      searchContainer: searchContainerStyle,
                    }
                  : {
                      height: height - 110,
                      searchContainer: searchContainerStyle,
                    }
              }
              getData={
                partnerId && isUnifiedPartner
                  ? fetchB2cTestData
                  : partnerId || partnerIdfromHistory
                  ? fetchPartnerTestAndCheckupsData
                  : fetchTestData
              }
              list={list[activeTab]}
              checkupsResultsCount={list?.packages?.length}
              testsResultsCount={list?.tests?.length}
              updateList={updateList}
              renderSearchItems={({item}) => {
                return (
                  <>
                    <If
                      condition={activeTab === 'packages'}
                      render={() => (
                        <RenderCheckupItem
                          selectedCheckups={selectedCheckups}
                          checkup={item}
                          calculatePercent={calculatePercent}
                          calculatePackageTAT={calculatePackageTAT}
                          updateSelectedCheckups={updateSelectedCheckups}
                          MixpanelService={MixpanelService}
                          cleverTapTrackEvent={cleverTapTrackEvent}
                          renderGroupTests={renderGroupTests}
                          searchTerm={searchTerm}
                          CheckupTestModal={CheckupTestModal}
                        />
                      )}
                    />
                    <If
                      condition={activeTab === 'tests'}
                      render={() => (
                        <RenderTestItem
                          test={item}
                          selectedTests={selectedTests}
                          renderTAT={renderTAT}
                          onPressSelect={onPressSelect}
                          renderGroupTests={renderGroupTests}
                          searchTerm={searchTerm}
                          updateShowGroupTestsModal={updateShowGroupTestsModal}
                          showGroupTestModal={showGroupTestModal}
                        />
                      )}
                    />
                  </>
                );
              }}
            />
          )}
        />
      </ScrollView>
      <If
        condition={shouldShowCheckupsAddButton() || shouldShowTestsAddButton()}
        render={() => (
          <View style={styles.orderBtnWrapper}>
            <SubmitButton
              onSubmit={() => {
                MixpanelService.track('Search - add test/checkup CTA');
                history.replace({
                  pathname: history.location.state?.from || '/public/order',
                  search: history.location.state?.search,
                });
              }}
              accessible
              accessibilityLabel="Add checkups"
              renderText={() => (
                <View style={styles.bookButtonContentWrapper}>
                  <View>
                    <AppText style={styles.bookButtonText}>
                      Add tests / checkups
                    </AppText>
                    <AppText style={styles.bookButtonSubText}>
                      {selectedTests.length > 1
                        ? `${selectedTests.length} tests`
                        : selectedTests.length > 0 &&
                          `${selectedTests.length} test`}
                      {selectedCheckups.length > 0 &&
                        selectedTests.length > 0 &&
                        ' , '}
                      {selectedCheckups.length > 1
                        ? `${selectedCheckups.length} checkups`
                        : selectedCheckups.length > 0 &&
                          `${selectedCheckups.length} checkup`}
                      {' selected'}
                    </AppText>
                  </View>
                  <LottieView
                    style={styles.bookButtonAnimation}
                    source={require('../../../../../shared/static/animations/movingArrows.json')}
                    speed={1.5}
                    autoPlay
                    loop
                  />
                </View>
              )}
              buttnProps={{nativeID: 'add-tests-btn'}}
            />
          </View>
        )}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#fff6ea',
    opacity: 1,
    backgroundSize: '7px 7px',
    backgroundImage:
      'repeating-linear-gradient(45deg, rgb(255 255 255 / 54%) 0px, rgb(142 142 142 / 26%) 0.7px, rgb(255, 246, 234) 0px, rgb(255, 246, 234) 50%)',
  },
  scrollContent: {
    backgroundColor: '#fff',
    width: '100%',
    minHeight: '100vh',
  },
  header: {
    paddingHorizontal: 25,
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerUpper: {
    backgroundColor: '#eceee6',
    paddingBottom: '47px',
  },
  heading: {
    color: '#000',
    fontSize: 14,
    marginBottom: 3,
    fontWeight: 'bold',
    marginLeft: 12,
    textTransform: 'uppercase',
  },
  addressPin: {
    width: 11,
    height: 11,
    marginRight: 2,
    marginLeft: 12,
  },
  subHeadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subHeadingTextContainer: {
    flexDirection: 'row',
  },
  subHeadingGreenText: {
    fontSize: 10,
    fontWeight: '600',
    color: '#01b37a',
    textTransform: 'uppercase',
    fontFamily: 'montserrat-semibold',
  },
  subHeadingGreyText: {
    fontSize: 10,
    fontWeight: '600',
    color: '#7d839a',
    textTransform: 'uppercase',
  },
  backButton: {
    width: 44,
    height: 44,
    marginTop: 5,
  },
  searchContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    marginTop: 20,
  },
  searchIconWrapper: {
    position: 'absolute',
    right: '10%',
    elevation: 5,
    zIndex: 5,
  },
  searchIcon: {
    height: 18,
    width: 18,
  },
  search: {
    color: '#616161',
    height: 55,
    paddingRight: '12%',
    width: '90%',
    borderColor: '#fff6ea',
    borderWidth: 1,
    borderRadius: 8,
  },
  tests: {
    paddingBottom: 50,
    paddingTop: 15,
  },
  test: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: '#fff',
    minHeight: 80,
    marginHorizontal: 20,
    marginBottom: 16,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },
  testMeta: {
    flex: 1,
    marginTop: 6,
    marginBottom: 16,
  },
  testText: {
    color: '#1c1c1c',
    fontSize: 14,
    fontWeight: 'bold',
    minWidth: '80%',
  },
  rupee: {
    color: '#7d839a',
    fontSize: 12,
    fontWeight: '500',
  },
  price: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#53596c',
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {height: 300},
  paginationLoaderWrapper: {alignItems: 'center'},

  orderBtnWrapper: {
    height: 65,
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 15,
    width: '88%',
    maxWidth: 460,
    position: 'absolute',
    bottom: 0,
  },
  checkBoxIcon: {
    width: 24,
    height: 24,
    marginLeft: 14,
  },
  selection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  testName: {
    fontSize: 12,
    color: '#53596c',
    marginTop: 8,
    overflow: 'visible',
    fontFamily: 'montserrat-medium',
  },
  testPrice: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  rupee2: {
    fontSize: 9,
    color: '#9d9d9d',
    marginLeft: 5,
  },
  totalPrice: {
    fontSize: 12,
    color: '#9d9d9d',
    textDecorationLine: 'line-through',
  },
  discount: {
    color: '#616161',
    fontSize: 10,
    marginLeft: 5,
  },
  testData: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 16,
  },
  category: {
    color: '#7d839a',
    fontSize: 10,
  },
  categoryText: {
    color: '#7d839a',
    fontSize: 10,
    fontFamily: 'montserrat-bold',
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tag: {
    paddingVertical: 3,
    paddingHorizontal: 6,
    flexDirection: 'row',
    marginTop: 10,
    alignSelf: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#f6f0fd',
    borderRadius: 3,
  },
  tagText: {
    fontSize: 9,
    fontWeight: 'bold',
    color: '#750ea3',
    marginRight: 2,
  },
  tagIcon: {
    width: 10,
    height: 10,
    marginTop: 1,
  },
  cantFindTestsCard: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#ffe8ca',
    backgroundColor: '#fff',
    width: '85%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  cantFindTestsCardTitle: {
    fontSize: 14,
    lineHeight: 18,
    textAlign: 'center',
    color: '#3c3c3c',
    marginBottom: 10,
    fontFamily: 'montserrat-bold',
  },
  cantFindTestsCardSubtext: {
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 16,
    textAlign: 'center',
    color: '#616161',
    marginBottom: 10,
  },
  cantFindTestsCTA: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: '#ff970a',
    shadowColor: 'rgba(199, 117, 4, 0.15)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 8,
    shadowOpacity: 1,
    paddingHorizontal: 34,
    paddingVertical: 12,
    // borderRadius: 8,
    // borderWidth: 1,
    // borderColor: '#ff970a',
    // backgroundColor: '#fff',
    flex: 1,
  },
  cantFindTestsCTAText: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    marginHorizontal: 10,
  },
  notFound: {
    marginVertical: 30,
    flexDirection: 'column',
    alignItems: 'center',
    width: '70%',
  },
  notFoundTitle: {
    fontSize: 14,
    color: '#616161',
    marginBottom: 10,
    fontFamily: 'montserrat-bold',
  },
  notFoundSubTitle: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'center',
    color: '#616161',
  },
  bookButtonWrapper: {
    width: '100%',
    height: 70,
    alignSelf: 'center',
    marginVertical: 24,
  },
  bookButtonContentWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  bookButtonAnimation: {
    width: 72,
    height: 72,
  },
  bookButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'montserrat-bold',
    paddingLeft: 11,
  },
  bookButtonSubText: {
    color: '#fff',
    fontSize: 14,
    paddingLeft: 11,
  },
  WAIcon: {width: 24, height: 24},
});
