import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Image,
  Platform,
  Pressable,
  SectionList,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import {useHistory, useParams} from 'react-router';
import AppError from '../../../../../shared/components/common/AppError/AppError';
import AppInput from '../../../../../shared/components/common/AppInput/AppInput';
import AppText from '../../../../../shared/components/common/AppText/AppText';
import Loader from '../../../../../shared/components/common/Loader/Loader';
import elevationShadowStyle from '../../../../../shared/helpers/shadowElevation';
import useDebounce from '../../../../../shared/hooks/useDebounce';
import useStableRef from '../../../../../shared/hooks/useStableRef';
import If from '../../common/If/If';

function keyExtractor(item, index) {
  return `${item.id}_${item.name}_${index}`;
}

export default function UnifiedSearchList({
  searchPlaceholder,
  searchIcon,
  getData,
  onSearch,
  noDataText,
  renderSearchItems,
  primarySectionHeader,
  secondarySectionHeader,
  frequentData,
  children,
  style = {},
  list = [],
  updateList,
  selectedCity = null,
  isLocationLoading = false,
  hideTestList = false,
  handleTabs,
  checkupsResultsCount = 0,
  testsResultsCount = 0,
}) {
  const {tab: activeTab, patientId} = useParams();
  const [searchTerm, updateSearchTerm] = useState('');
  const [isLoading, updateIsLoading] = useState(true);
  const [error, updateError] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const requestControllerRef = useRef(null);
  const getDataStableRef = useStableRef(getData);
  const onSearchStableRef = useStableRef(onSearch);
  const history = useHistory();
  const ref = useRef();

  useEffect(() => {
    if (
      activeTab === 'packages' &&
      checkupsResultsCount === 0 &&
      testsResultsCount > 0
    ) {
      history.replace({
        pathname: `/public/add-tests-checkups/tests/${patientId}`,
        state: {
          ...history.location.state,
        },
      });
      history.replace();
    } else if (
      activeTab === 'tests' &&
      testsResultsCount === 0 &&
      checkupsResultsCount > 0
    ) {
      history.replace({
        pathname: `/public/add-tests-checkups/packages/${patientId}`,
        state: {
          ...history.location.state,
        },
      });
    }
  }, [checkupsResultsCount, testsResultsCount]);

  const fetchTestData = useCallback(
    async search => {
      const newRequestController = new AbortController();
      if (requestControllerRef.current) {
        requestControllerRef.current.abort();
      }
      requestControllerRef.current = newRequestController;
      return getDataStableRef.current({
        search,
        abortController: newRequestController,
      });
    },
    [requestControllerRef, getDataStableRef],
  );

  useEffect(() => {
    async function getTestData() {
      updateIsLoading(true);
      updateList([{tests: [], packages: []}]);

      try {
        const data = await fetchTestData(debouncedSearchTerm);

        updateList(data);
        onSearchStableRef.current(debouncedSearchTerm);
      } catch (e) {
        if (e?.name !== 'AbortError') {
          updateList({tests: [], packages: []});
          updateError(e.message || "Couldn't load");
        }
      }
      updateIsLoading(false);
    }
    if (
      (debouncedSearchTerm?.length > 1 || debouncedSearchTerm?.length === 0) &&
      !isLocationLoading
    ) {
      getTestData();
    }
    return () => {
      if (requestControllerRef.current) {
        requestControllerRef.current.abort();
      }
    };
  }, [
    debouncedSearchTerm,
    fetchTestData,
    onSearchStableRef,
    selectedCity,
    isLocationLoading,
  ]);

  const sections = useCallback(() => {
    if (
      frequentData &&
      frequentData?.length > 0 &&
      !debouncedSearchTerm &&
      list.length
    ) {
      const lists = hideTestList
        ? [
            {
              title: secondarySectionHeader || [],
              data: frequentData || [],
            },
          ]
        : [
            {
              title: secondarySectionHeader || [],
              data: frequentData || [],
            },
            {
              title: primarySectionHeader,
              data: list,
            },
          ];
      return [...lists];
    } else if (list.length > 0 && !debouncedSearchTerm) {
      return [
        {
          title: primarySectionHeader,
          data: list,
        },
      ];
    } else if (list.length > 0) {
      return [
        {
          title: null,
          data: list,
        },
      ];
    } else if ((!isLoading || !error) && !isLocationLoading) {
      return [];
    }
    return [];
  }, [isLoading, error, frequentData, list, isLocationLoading, activeTab]);

  const activeTabTextStyle = useCallback(
    tab => {
      if (tab === activeTab) {
        return activeTabText;
      } else {
        return inactiveTabText;
      }
    },
    [activeTab],
  );
  useEffect(() => {
    // ref?.current?.focus();
  }, [ref]);
  return (
    <View style={{...style}}>
      <View
        style={
          style?.searchContainer
            ? style?.searchContainer
            : styles.searchContainer
        }>
        <AppInput
          ref={ref}
          style={searchbarStyle}
          value={searchTerm}
          onChangeText={updateSearchTerm}
          placeholder={searchPlaceholder}
          placeholderTextColor="#9d9d9d"
        />
        <View style={styles.searchIconWrapper}>
          <Image style={styles.searchIcon} source={searchIcon} />
        </View>
      </View>
      <View style={styles.tabNavContainer}>
        <Pressable
          style={styles.tabButton}
          onPress={() => {
            if (handleTabs) {
              handleTabs('tests');
            }
          }}>
          <View style={styles.tabTextContainer}>
            <Text style={activeTabTextStyle('tests')}>TESTS</Text>
            {debouncedSearchTerm.length >= 2 && (
              <View style={styles.badgeContainer}>
                <Text style={styles.badgeText}>
                  {!isLoading && testsResultsCount}
                </Text>
              </View>
            )}
          </View>
          <If
            condition={activeTab === 'tests'}
            render={() => <View style={styles.tabActiveStyles} />}
          />
        </Pressable>
        <Pressable
          style={styles.tabButton}
          onPress={() => {
            if (handleTabs) {
              handleTabs('packages');
            }
          }}>
          <View style={styles.tabTextContainer}>
            <Text style={activeTabTextStyle('packages')}>PACKAGES</Text>
            {debouncedSearchTerm.length >= 2 && (
              <View style={styles.badgeContainer}>
                <Text style={styles.badgeText}>
                  {!isLoading && checkupsResultsCount}
                </Text>
              </View>
            )}
          </View>
          <If
            condition={activeTab === 'packages'}
            render={() => <View style={styles.tabActiveStyles} />}
          />
        </Pressable>
      </View>
      <SectionList
        sections={sections()}
        showsHorizontalScrollIndicator={false}
        extraData={{isLoading, debouncedSearchTerm}}
        windowSize={8}
        maxToRenderPerBatch={8}
        renderSectionHeader={({section: {title}}) => (
          <If
            condition={title}
            render={() => (
              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: '#1c1c1c',
                  paddingLeft: 24,
                  marginTop: 8,
                  marginBottom: 8,
                }}>
                {title}
              </AppText>
            )}
          />
        )}
        keyExtractor={keyExtractor}
        keyboardShouldPersistTaps="handled"
        stickySectionHeadersEnabled={false}
        ListEmptyComponent={
          <>
            <If
              condition={error}
              render={() => (
                <View style={styles.centered}>
                  <AppError>Error: {error}</AppError>
                </View>
              )}
            />
            <If
              key="loading"
              condition={isLoading || isLocationLoading}
              render={() => <Loader style={styles.loader} />}
            />
            <If
              key="no-list"
              condition={!isLoading && !error && !isLocationLoading}
              render={() => {
                if (!isLoading && !error && children) {
                  return <View style={styles.centered}>{children}</View>;
                } else {
                  return (
                    <View style={styles.centered}>
                      <AppText>{noDataText || 'No tests found'}</AppText>
                    </View>
                  );
                }
              }}
            />
          </>
        }
        renderItem={props => renderSearchItems(props, searchTerm)}
      />
    </View>
  );
}

const searchbarStyle = {
  color: '#616161',
  paddingRight: '12%',
  borderColor: 'transparent',
  borderWidth: 1,
  borderRadius: 8,
  ...Platform.select({
    web: {
      ...elevationShadowStyle(0),
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.03)',
    },
    android: {
      shadowColor: '#bfc0c0',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.001,
      shadowRadius: 2.22,
      elevation: 3,
    },
    ios: {
      shadowColor: '#000000',
      elevation: 1,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.06,
      shadowRadius: 2,
    },
  }),
};

const styles = StyleSheet.create({
  searchContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    marginTop: 20,
  },
  searchIconWrapper: {
    position: 'absolute',
    right: '10%',
    elevation: 5,
    zIndex: 5,
  },
  searchIcon: {
    height: 18,
    width: 18,
  },
  list: {
    paddingBottom: 50,
    paddingTop: 15,
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {height: 300},
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#1c1c1c',
    paddingLeft: 24,
    marginTop: 8,
    marginBottom: 8,
  },
  tabNavContainer: {
    height: 36,
    backgroundColor: '#ffffff',
    shadowColor: 'rgba(0, 0, 0, 0.03)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
  },
  tabActiveStyles: {
    height: 1,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#01b37a',
  },
  tabText: {
    color: 'red',
  },
  tabButton: {
    flex: 0.5,
    justifyContent: 'space-between',
    height: '100%',
  },
  tabTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  badgeContainer: {
    width: 20,
    height: 16,
    borderRadius: 7.5,
    backgroundColor: '#f2f2f2',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 10,
  },
  badgeText: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#7b7b7b',
  },
});

const activeTabText = {
  fontFamily: 'montserrat-medium',
  fontSize: 12,
  fontWeight: '600',
  fontStyle: 'normal',
  letterSpacing: 0,
  color: '#01b37a',
};

const inactiveTabText = {
  fontFamily: 'montserrat-medium',
  fontSize: 12,
  fontWeight: '600',
  fontStyle: 'normal',
  letterSpacing: 0,
  color: '#7b7b7b',
};
