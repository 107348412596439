export default {
  apiKey: "AIzaSyBzd4f96mw9jBRv8ODYAlxccRkvKKBw3yk",
  authDomain: "orangehealth-production.firebaseapp.com",
  databaseURL: "https://orangehealth-production.firebaseio.com",
  projectId: "orangehealth-production",
  storageBucket: "orangehealth-production.appspot.com",
  messagingSenderId: "38392073702",
  appId: "1:38392073702:web:549134ea5368974b335a27",
  measurementId: "G-5FJSRV0PVM"
};
