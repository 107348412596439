import codePush from "react-native-code-push";

export async function getAppVersion() {
  const version = await codePush
    .getUpdateMetadata()
    .then((update) => {
      if (update && update.description) {
        return update.description;
      }
    })
    .catch((error) => {
      return error.toString();
    });

  return version || '';
}
