import * as React from 'react';
import {Provider} from 'react-redux';
import {Linking} from 'react-native';
import {BrowserRouter as Router} from 'react-router-dom';
import Bugsnag from '@bugsnag/js';

import Public from '../Public/Public';

import {createNewStore} from '../../store/mainStore';
import mainStore from '../../../../shared/store/mainStore';
import {getConfig} from '../../../../shared/config/config';
import {bugsnagStart} from '../../../../shared/helpers/bugsnagStart';

import './MainContainer.scss';

bugsnagStart();

const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment;

const whitelistingRoutes = [
  {web: 'public/order-details', app: 'order-details'},
];

async function initGATagManager() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
  const firstScript = document.getElementsByTagName('script')[0];
  const newScript = document.createElement('script');
  newScript.async = true;
  newScript.src =
    'https://www.googletagmanager.com/gtm.js?id=' + getConfig().gaTagMangerId;
  firstScript.parentNode.insertBefore(newScript, firstScript);
}

window.addEventListener('load', () => {
  initGATagManager();
});

export default class MainContainerApp extends React.Component {
  constructor() {
    super();
    this.myStore = mainStore(createNewStore);
  }

  componentDidMount() {
    let path = window.location.pathname.substring(1);
    let search = window.location.search;

    let {userAgent} = window.navigator;

    if (!userAgent) {
      userAgent = navigator.userAgent || navigator.vendor || window.opera;
    }

    const appPrefix = 'orangehealthpatient://';

    if (/Android|iPhone/i.test(userAgent)) {
      if (path === '/') {
        return;
      }
      let isRouteWhitelisted = whitelistingRoutes.find(route =>
        path.includes(route.web),
      );

      if (!isRouteWhitelisted) {
        return;
      }
      let searchParamsObj = new URLSearchParams(search);

      if (isRouteWhitelisted) {
        searchParamsObj.append('ACTION', 'navigate');
        searchParamsObj.append('ROUTE', isRouteWhitelisted?.app);
      }
      let searchParamsForDeeplink = searchParamsObj.toString();
      Linking.canOpenURL(`${appPrefix}${path}?${searchParamsForDeeplink}`)
        .then(res => {
          if (res) {
            Linking.openURL(`${appPrefix}${path}?${searchParamsForDeeplink}`);
          }
        })
        .catch(() => {});
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <React.StrictMode>
          <Provider store={this.myStore}>
            <Router>
              <Public />
            </Router>
          </Provider>
        </React.StrictMode>
      </ErrorBoundary>
    );
  }
}
