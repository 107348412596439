import React, { Component } from 'react';
import { Platform, Text } from 'react-native';

const styles = {
  text: {
    fontFamily: 'Montserrat-Medium',
    // ios fonts were thin by default so setting the normal as 500
    fontWeight: Platform.OS == 'ios' ? '500' : 'normal',
  },
  boldText: {
    fontFamily: 'Montserrat-Bold',
    fontWeight: Platform.OS == 'ios' ? '700' : 'normal', // As IOS is not supporting default font family
  },
};

export default class AppText extends Component {
  constructor(props) {
    super(props);
    const addedStyle = { ...props.style };
    this.style = [styles.text];
    if (props.style) {
      if (addedStyle.fontWeight === 'bold') {
        this.style = [styles.boldText];
      }
      if (Platform.OS === 'android') delete addedStyle.fontWeight;
      if (Array.isArray(addedStyle)) {
        this.style = this.style.concat(addedStyle);
      } else {
        this.style.push(addedStyle);
      }
    }
  }

  render() {
    return (
      <Text {...this.props} style={this.style}>
        {this.props.children}
      </Text>
    );
  }
}
