export const events = {
    CITY_CHANGED : 'city_changed',
    BOOK_SLOT : 'book_slot',
    ORDER_PLACED: 'Order placed',
    ORDER_PLACED_CAMPS: 'camp_order_placed',
    USER_NAME_CAMPS: 'camp_user_name_entered',
    USER_MOBILE_CAMPS: 'camp_user_mobile_number_entered',
    ORDER_FORM_PAGE_VISIT: 'order_form_page_visit',
    USER_DETAILS_ENTERED: 'user_details_entered',
    ADDRESS_ADDED_SUCCESSFULLY: 'address_added_successfully',
    TESTS_ADDED: 'tests_added',
    CHECKUPS_ADDED: 'checkups_added',
    TEST_SELECTED: 'test_selected',
    CHECKUP_SELECTED: 'checkups_selected',
    TEST_UNSELECTED: 'test_unselected',
    CHECKUP_UNSELECTED: 'checkup_unselected',
    OLD_USER: 'old_user',
    NEW_USER: 'new_user',
    INCOMPLETE_ADDRESS: 'Incomplete Address',
    COMPLETE_ADDRESS: 'Complete Address',
    LOCATION_UNSERVICEABLE: 'Location unserviceable',
}