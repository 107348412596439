import React from 'react';
import {TouchableOpacity} from 'react-native';

import If from '../../../../../shared/components/common/If/If';
import MiniLoader from '../../../../../shared/components/common/Loader/MiniLoader';

import './SubmitButton.scss';

export default function SubmitButton(props) {
  return (
    <TouchableOpacity
      onPress={() => !props.isLoading && props.onSubmit()}
      disabled={props.disabled}
      style={styles.submitButton}
      {...(props.buttnProps || {})}>
      <div
        style={props.buttonStyle}
        className={`submit-button ${
          props.disabled ? 'submit-button-disabled' : ''
        }`}>
        <If condition={props.isLoading} render={() => <MiniLoader />} />
        <If
          condition={!props.isLoading && !props.renderText}
          render={() => (
            <div
              style={props.textStyle}
              className={`submit-button-text ${
                props.disabled ? 'submit-button-text-disabled' : ''
              }`}>
              {props.buttonText}
            </div>
          )}
        />
        <If
          condition={!props.isLoading && props.renderText}
          render={props.renderText}
        />
      </div>
    </TouchableOpacity>
  );
}
const styles = {
  submitButton: {
    width: '100%',
    height: '100%',
  },
};
