import React from 'react';
import LottieView from 'lottie-react-native';

export default function MiniLoader({ theme, style = {} }) {
  const loaderStyles = { ...styles.loader, ...style };
  if (theme === 'orange') {
    return (
      <LottieView style={loaderStyles} source={require('../../../static/animations/loading-orange.json')} autoPlay loop />
    );
  }
  return (
    <LottieView style={loaderStyles} source={require('../../../static/animations/loading.json')} autoPlay loop />
  );
}

const styles = {
  loader: {
    width: 70,
    height: 70,
  },
};
