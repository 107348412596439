export default function frequentItemsOnTop(
  frequentTests = [],
  searchResults = [],
) {
  const testsFromSearch = [...searchResults];
  const sortedTests = frequentTests
    .sort((first, second) => first?.id - second?.id)
    .filter(item => {
      return testsFromSearch.some((testItem, index) => {
        if(testItem?.id === item?.id) {
          testsFromSearch.splice(index, 1);
          return true;
        }
        return false;
      })
    });
  return [...sortedTests, ...testsFromSearch];
};