import { getConfig } from "../config/config";
import { PARTNER_WEB_ORDER_FORM } from "../../shared/constants/partnerWebOrderForm";

export const SEARCH_TYPES = {
  checkup: "checkup",
  test: "test",
  panel: "panel",
};

export async function orderTestPublic(body) {
  const res = await fetch(
    `${getConfig().apiHost}/order/customer/direct/web/?version=v2`,
    {
      method: "POST",
      body,
    }
  );
  if (res.status < 400) {
    const data = await res.json();
    return data;
  }
  if (res.status === 400) throw Error("Slot unavailable, Please try again.");
  if (res.status === 429)
    throw Error(
      "We’re sorry, but you have sent too many requests to us recently. Please try again later."
    );
  throw Error("Couldn't place the order, Please try again");
}

export async function emedicRating(id) {
  const res = await fetch(
    `${getConfig().apiHost}/order/emedic/feebacklink/?oms_request_id=${id}`
  );
  if (res.status !== 200) {
    throw res;
  }
  return await res.json();
}

export async function getInvoiceURL(id, token) {
  const res = await fetch(
    `${getConfig().apiHost}/invoice/${id}/?token=${token}`
  );
  if (res.status !== 200) {
    throw res;
  }
  return await res.json();
}

export async function getDoctorDetailsFromOrangeId(orangeId) {
  const res = await fetch(`${getConfig().apiHost}/doctor/${orangeId}/details/`);
  return res.json();
}

export async function getAvailableSlots(lat, long, date, abortController) {
  const url = new URL(`${getConfig().scheduler}/task/public/available/slot/`);
  url.searchParams.append("latitude", lat);
  url.searchParams.append("longitude", long);
  url.searchParams.append("request_date", date);
  const res = await fetch(url, {
    method: "GET",
    signal: abortController.signal,
  });
  if (res.status === 200) return res.json();
  if (res.status === 429)
    throw Error(
      "We’re sorry, but you have sent too many requests to us recently. Please try again later."
    );
  throw Error("Unserviceable Region");
}

export async function bookSlot(lat, long, timeslot, mobileNumber) {
  const body = new FormData();
  body.append("latitude", lat);
  body.append("longitude", long);
  body.append("requested_slot_time", timeslot);
  body.append("mobile_number", mobileNumber);
  const res = await fetch(`${getConfig().scheduler}/task/lock/slot/`, {
    method: "POST",
    body,
  });
  if (res.status < 300) return res.json();
  if (res.status === 429)
    throw Error(
      "We’re sorry, but you have sent too many requests to us recently. Please try again later."
    );
  throw Error("Slot unavailable, Please try again.");
}

export async function getRequestForSrf(requestId, token) {
  const res = await fetch(
    `${getConfig().omsApiHost}/public/request/${requestId}/srf?token=${token}`
  );
  if (res.status === 404) {
    throw Error(res.status);
  }
  return res.json();
}

export async function uploadAddressProofFile(
  fileUploadURL,
  orderId,
  requestId,
  file,
  filetype,
  token
) {
  const body = new FormData();
  body.append("file", file);
  body.append("fileType", filetype);
  await fetch(
    `${fileUploadURL}/public/request/${requestId}/order/${orderId}/srf/file?token=${token}`,
    {
      method: "POST",
      body,
    }
  );
}

export async function getAllAddressProofs(orderId, requestId, token) {
  const res = await fetch(
    `${
      getConfig().omsApiHost
    }/public/request/${requestId}/order/${orderId}/srf/files?token=${token}`
  );
  return res.json();
}

export async function deleteAddressProof(fileId, requestId, token) {
  const res = await fetch(
    `${
      getConfig().omsApiHost
    }/public/request/${requestId}/srf/file/${fileId}?token=${token}`,
    {
      method: "DELETE",
    }
  );
  return res.json();
}

export async function updateRequestForSrf(body, requestId, token) {
  const res = await fetch(
    `${getConfig().omsApiHost}/public/request/${requestId}/srf?token=${token}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  if (res.status < 300) return res.json();
}

export async function generateSrfId(body, requestId, token) {
  const res = await fetch(
    `${
      getConfig().omsApiHost
    }/public/request/${requestId}/generate-srf-id?token=${token}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  if (res.status < 300) return res.json();
}

export async function getOrderDetails(id, token) {
  const url = new URL(`${getConfig().apiHostV3}/order/details/`);
  url.searchParams.append("oms_request_id", id);
  url.searchParams.append("token", token);
  const res = await fetch(url);
  if (res.status === 200) return res.json();
  throw Error("Couldn't get order details");
}

export async function getOrderDetailsV4(id, token, accessToken) {
  const url = new URL(`${getConfig().apiHostV4}/order/details/`);
  url.searchParams.append("oms_request_id", id);
  url.searchParams.append("token", token);
  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (res.status === 200) {
    return res.json();
  } else if (res.status === 401) {
    throw new Error("Unauthorized");
  } else if (res.status === 403) {
    throw new Error("Forbidden");
  }
  throw Error("Couldn't get order details");
}

export async function getOrderDetailsV3(id, token) {
  const url = new URL(`${getConfig().apiHostV3}/order/details/`);
  url.searchParams.append("oms_request_id", id);
  url.searchParams.append("token", token);
  const res = await fetch(url);
  if (res.status === 200) return res.json();
  throw Error("Couldn't get order details");
}

let requestController;
export async function getVoucherValidity(
  voucher_code,
  mobileNumber,
  patients,
  city_code
) {
  try {
    // abort any running request
    const newRequestController = new AbortController();
    if (requestController) requestController.abort();
    requestController = newRequestController;

    const body = {
      voucher_code,
      customer_number: mobileNumber,
      patients: patients.map((p) => ({
        age: p.age,
        tests: p?.tests?.map((item) => ({ masterTestId: item?.id })) || [],
      })),
      city_code,
    };
    const res = await fetch(`${getConfig().apiHost}/order/voucher/validate/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      signal: newRequestController.signal,
    });
    // voucher not found
    if (res.status == 400) {
      return {
        isValid: false,
        message: "Voucher not valid",
        voucherCode: voucher_code,
      };
    }
    // Not known error
    if (res.status >= 300) {
      return {
        isValid: false,
        message: "Couldn't check voucher validity",
        voucherCode: voucher_code,
      };
    }
    const voucherResults = await res.json();
    const result = {
      isValid: false,
      voucherCode: voucherResults[0].voucherCode,
    };
    for (let voucherResult of voucherResults) {
      if (!voucherResult.errorApplyingVoucher) {
        result.isValid = true;
        result.message = voucherResult.voucherInfo;
        break;
      }
    }
    if (!result.isValid) {
      result.message = voucherResults[0].errorApplyingVoucher;
    }
    return result;
  } catch (e) {
    if (e?.name === "AbortError")
      return {
        isValid: false,
        voucherCode: voucher_code,
      };
    return {
      isValid: false,
      message: "Couldn't check voucher validity",
      voucherCode: voucher_code,
    };
  }
}

export async function getIsRepeatCustomer(mobileNumber) {
  const url = new URL(
    `${getConfig().apiHost}/customer/is_address_available/${mobileNumber}/`
  );
  const res = await fetch(url);
  if (res.status === 200) return true;
  return false;
}

export async function getAllCities() {
  const res = await fetch(`${getConfig().scheduler}/hub/cities/`);
  const resBody = await res.json();
  if (res.status === 200 && !resBody?.bodyUsed) {
    return resBody;
  }
  throw Error("Couldn't load cities");
}

export async function getAllTestsCheckups({
  cityCode,
  search,
  abortController,
  limit,
  keyToGet,
}) {
  const res = await fetch(
    `${
      getConfig().apiHost
    }/customer/tests/?city_code=${cityCode}&limit=${limit}&search_substring=${search}`,
    {
      method: "GET",
      signal: abortController.signal,
    }
  );
  const resBody = await res.json();
  if (res.status === 200 && !resBody.bodyUsed) {
    // Sorts the tests and packages in the descreasing order of their booked frequency
    if (keyToGet == "tests" || keyToGet == "packages") {
      resBody[keyToGet].sort((a, b) => b.bookedFrequency - a.bookedFrequency);
    }
    return resBody[keyToGet]?.map((item) => ({
      ...item,
      logisticTat: resBody?.logisticTat || 0,
    }));
  }
  if (res.status >= 300 && !resBody.bodyUsed) {
    throw Error(resBody.detail);
  }
}

export async function getAllB2cPartnerTests({
  partnerId,
  search,
  abortController,
  limit,
  keyToGet,
  cityCode,
}) {
  const res = await fetch(
    `${
      getConfig().apiHostV2
    }/doctor/public/tests/?partner_id=${partnerId}&limit=${limit}&search_substring=${search}&city_code=${cityCode}`,
    {
      method: "GET",
      signal: abortController.signal,
    }
  );
  const resBody = await res.json();
  const { status } = res || {};
  const { bodyUsed, packages, panels } = resBody || {};
  const allPackagePanels = packages?.concat(panels);

  if (status === 200 && !bodyUsed && keyToGet == "tests") {
    if (keyToGet == "tests") {
      resBody[keyToGet].sort((a, b) => b.bookedFrequency - a.bookedFrequency);
    }
    return resBody[keyToGet]?.map((item) => ({
      ...item,
      logisticTat: resBody?.logisticTat || 0,
    }));
  }

  if (status === 200 && !bodyUsed && keyToGet == "packages") {
    if (keyToGet == "packages") {
      allPackagePanels?.sort((a, b) => b.bookedFrequency - a.bookedFrequency);
    }
    return allPackagePanels?.map((item) => ({
      ...item,
      logisticTat: resBody?.logisticTat || 0,
    }));
  }
  if (status >= 300 && !bodyUsed) {
    throw Error(resBody?.detail);
  }
}

export async function getAllPartnerCheckups({
  cityCode,
  search,
  abortController,
  limit,
  keyToGet,
  partnerId,
}) {
  const source = PARTNER_WEB_ORDER_FORM;
  const res = await fetch(
    `${
      getConfig().omsApiHost
    }/public/partner-checkups?q[type]=package&category=partner&q[is_custom]=1&limit=${limit}&city_code=${cityCode}&partnerID=${partnerId}&q[packageName]=${search}&source=${source}`,
    {
      method: "GET",
      signal: abortController.signal,
    }
  );
  const resBody = await res.json();
  if (res.status === 200 && !resBody.bodyUsed) {
    // Sorts the tests and packages in the descreasing order of their booked frequency
    if (keyToGet == "packages") {
      resBody[keyToGet].sort((a, b) => b.bookedFrequency - a.bookedFrequency);
    }
    return resBody[keyToGet]?.map((item) => ({
      ...item,
      logisticTat: resBody?.logisticTat || 0,
    }));
  }
  if (res.status >= 300 && !resBody.bodyUsed) {
    throw Error(resBody.detail);
  }
}

export async function getStartupDetails() {
  const res = await fetch(`${getConfig().apiHost}/customer/startup/details`, {
    method: "GET",
  });
  if (res.status === 200) {
    return res.json();
  }
  throw Error("Couldn't load startup details");
}

export async function getCampDetails({ campId, cityCode }) {
  const res = await fetch(
    `${getConfig().apiHost}/order/camp/${campId}/?city_code=${cityCode}`,
    {
      method: "GET",
    }
  );
  if (res.status === 200) {
    return res.json();
  }
  throw Error("Couldn't load camp details");
}

export async function orderTestCamp(body) {
  const campId = body.get("campId");
  const res = await fetch(
    `${getConfig().apiHost}/order/camp/${campId}/request/`,
    {
      method: "POST",
      body,
    }
  );
  if (res.status < 400) {
    const data = await res.json();
    return data;
  }

  if (res.status === 400) {
    const err = await res.json();
    if (err.error_code === "ORDERCREATION102") {
      throw Error(err?.error_code);
    }
  }
  if (res.status === 400)
    throw Error("Couldn't place the order, Please try again.");
  if (res.status === 429)
    throw Error(
      "We’re sorry, but you have sent too many requests to us recently. Please try again later."
    );
  throw Error("Couldn't place the order, Please try again");
}

export async function getFrequentTestsCheckups({
  cityCode,
  groupBy = "masterTest",
  keyToGet = "tests",
  abortController,
  user = "customer",
  partner_id,
}) {
  try {
    const res = await fetch(
      `${
        getConfig().apiHost
      }/${user}/tests/frequent/?city_code=${cityCode}&group_by=${groupBy}` +
        (partner_id ? `&partner_id=${partner_id}` : ""),
      {
        method: "GET",
        signal: abortController.signal,
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      return data?.[keyToGet]?.map((item) => ({
        ...item,
        logisticTat: data.logisticTat,
      }));
    } else {
      return [];
    }
  } catch {
    return [];
  }
}

export async function applyVoucher(data) {
  const res = await fetch(`${getConfig().apiHost}/order/voucher/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const resBody = await res.json();

  return resBody;
}

export async function removeAppliedVoucher(data) {
  const res = await fetch(`${getConfig().apiHost}/order/voucher/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const resBody = await res.json();
  return resBody;
}

export async function payLater(data) {
  const res = await fetch(
    `${getConfig().apiHost}/order/customer/direct/payment/later`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const resBody = await res.json();
  return resBody;
}

export async function getTestsCheckups({
  cityCode,
  getTestsCheckupsQuery,
  limit,
  user = "customer",
  partnerId,
}) {
  const res = await fetch(
    `${
      getConfig().apiHost
    }/${user}/tests/?city_code=${cityCode}&search_substring=${getTestsCheckupsQuery}&limit=${limit}` +
      (partnerId ? `&partner_id=${partnerId}` : "")
  );
  if (res.status === 200) {
    const data = await res.json();
    return data;
  }
  if (res.status >= 300) {
    const resBody = await res.json();
    throw Error(resBody.detail);
  }
}

export async function isLocationServiceable({ latitude, longitude }, logError) {
  const res = await fetch(
    `${
      getConfig().apiHost
    }/order/location/serviceable/?latitude=${latitude}&longitude=${longitude}`
  );
  const resBody = await res.json();
  if (logError && "error" in resBody) {
    throw new Error(resBody.error, resBody.error);
  }
  if (res.status === 200) {
    return resBody;
  }
  if (res.status > 300) {
    throw Error(resBody);
  }
}

export async function getPartnerReference(partnerCode, cityCode) {
  const res = await fetch(
    `${
      getConfig().apiHost
    }/partner/?partner_code=${partnerCode}&city_code=${cityCode}`,
    {
      method: "GET",
    }
  );
  const data = await res.json();
  return data;
}

export async function validatePartnerReference(partnerId, cityCode, data) {
  const res = await fetch(
    `${
      getConfig().apiHost
    }/partner/${partnerId}/reference-validate/?city_code=${cityCode}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  if (res.status === 200) {
    return res.json();
  }
  throw Error("Couldn't validate partner reference");
}

export async function getRecentDoctors(mobileNumber) {
  const res = await fetch(
    `${getConfig().apiHost}/customer/doctors/?mobile_number=${mobileNumber}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status === 200) {
    return res.json();
  }
  throw Error("Couldn't fetch doctors associated with this mobile number.");
}

export async function getDoctorsWithPartnerId(partnerId, cityCode) {
  const res = await fetch(
    `${
      getConfig().apiHost
    }/partner/doctors/?partner_id=${partnerId}&city_code=${cityCode}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (res.status === 200) {
    return res.json();
  }
  throw Error("Couldn't fetch doctors associated with this partner");
}

export async function getProductDetails({
  cityCode,
  // type = test/package
  type,
  productId, //orangePackageId
}) {
  let productType = type === SEARCH_TYPES.checkup ? "package" : "test";
  const res = await fetch(
    `${
      getConfig().apiHost
    }/customer/test-detail/?city_code=${cityCode}&test_type=${productType}&orange_${productType}_id=${productId}`,
    {
      method: "GET",
    }
  );
  const resBody = await res.json();
  if (res.status === 200 && !resBody.bodyUsed) {
    return resBody;
  }
  if (res.status >= 300 && !resBody.bodyUsed) {
    throw Error(resBody.detail);
  }
}

export async function getUnifiedTestsCheckups({
  cityCode,
  search,
  abortController,
  limit,
}) {
  const testUrl = `/customer/tests/?city_code=${cityCode}&limit=${limit}&search_substring=${search}`;

  const res = await fetch(`${getConfig().apiHost}${testUrl}`, {
    method: "GET",
    signal: abortController.signal,
  });
  const resBody = await res.json();
  if (res.status === 200 && !resBody.bodyUsed) {
    // Sorts the tests and packages in the descreasing order of their booked frequency
    let keyToGet = ["tests", "packages"];
    let payload = {};
    keyToGet.forEach((key) => {
      resBody[key].sort((a, b) => b.bookedFrequency - a.bookedFrequency);
      payload[key] = resBody[key]?.map((item) => ({
        ...item,
        logisticTat: resBody?.logisticTat || 0,
      }));
    });
    return payload;
  }
  if (res.status >= 300 && !resBody.bodyUsed) {
    throw Error(resBody.detail);
  }
}

export async function getPartnerTestsAndCheckups({
  cityCode,
  search,
  abortController,
  limit,
  partnerId,
}) {
  const testUrl = `/partner/tests/?city_code=${cityCode}&limit=${limit}&search_substring=${search}&partner_id=${partnerId}`;
  const res = await fetch(`${getConfig().apiHost}${testUrl}`, {
    method: "GET",
    signal: abortController.signal,
  });
  const resBody = await res.json();
  if (res.status === 200 && !resBody.bodyUsed) {
    // Sorts the tests and packages in the descreasing order of their booked frequency
    let keyToGet = ["tests", "packages"];
    let payload = {};
    keyToGet.forEach((key) => {
      resBody[key].sort((a, b) => b.bookedFrequency - a.bookedFrequency);
      payload[key] = resBody[key]?.map((item) => ({
        ...item,
        logisticTat: resBody?.logisticTat || 0,
      }));
    });
    return payload;
  }
  if (res.status >= 300 && !resBody.bodyUsed) {
    throw Error(resBody.detail);
  }
}

export async function getTestsCheckupsDoctor({
  cityCode,
  getTestsCheckupsQuery,
  limit,
  abortController,
}) {
  const res = await fetch(
    `${
      getConfig().apiHostV2
    }/doctor/public/tests/?limit=${limit}&search_substring=${getTestsCheckupsQuery}&city_code=${cityCode}`,
    {
      method: "GET",
      signal: abortController.signal,
    }
  );
  if (res.status === 200) {
    const data = await res.json();
    return data;
  }
  if (res.status >= 300) {
    const resBody = await res.json();
    throw Error(resBody.detail);
  }
}
