import BuildConfig from 'react-native-build-config';
import prodConfig from './prod.config';
import preProdConfig from './preprod.config';
import stagingConfig from './staging.config';
import localConfig from './local.config';

const LOCAL_DOMAIN = 'localhost';
const LOCAL_IP = '127.0.0.1';
const PROD_DOMAIN = 'web.orangehealth.in';
const PREPROD_DOMAIN = 'rc-web.orangehealth.dev';

function isDev() {
  return (
    window.location.hostname === LOCAL_DOMAIN ||
    window.location.hostname === LOCAL_IP
  );
}

function isProd() {
  return window.location.hostname === PROD_DOMAIN;
}

function isPreProd() {
  return window.location.hostname === PREPROD_DOMAIN;
}
let config = {};
if (typeof document === 'undefined') {
  if (
    BuildConfig.DEBUG === true ||
    BuildConfig.DEBUG === 'true' ||
    BuildConfig.BUILD_TYPE === 'debugStaging'
  ) {
    config = stagingConfig;
  } else {
    config = prodConfig;
  }
} else {
  const isLocal = isDev();
  const isProduction = isProd();
  const isPreProduction = isPreProd();
  if (isProduction) {
    config = prodConfig;
  } else if (isPreProduction) {
    config = preProdConfig;
  } else if (!isProduction && !isLocal) {
    config = stagingConfig;
  } else {
    config = stagingConfig;
  }
}

export const getConfig = () => config;
export const setConfig = (url) => (config = url);
