import prodConfig from './firebase-prod.config';
import preProdConfig from './firebase-preprod.config';
import stagingConfig from './/firebase-staging.config';

let config = {};
const isProduction = window.location.hostname === 'web.orangehealth.in';
const isPreprod = window.location.hostname === 'rc-web.orangehealth.dev';

if (isProduction) {
  config = prodConfig;
} else if (isPreprod) {
  config = preProdConfig;
} else if(!isProduction) {
  config = stagingConfig;
}

export default config;
