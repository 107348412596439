export default {
  web: "https://rc-web.orangehealth.dev",
  authHost: "https://rc-accounts.orangehealth.dev/api/v1",
  apiHost: "https://rc-api.orangehealth.dev/api/v1",
  apiHostV2: "https://rc-api.orangehealth.dev/api/v2",
  apiHostV3: "https://rc-api.orangehealth.dev/api/v3",
  apiHostV4: "https://rc-api.orangehealth.dev/api/v4",
  cerebroHost: "https://rc-cerebro.orangehealth.dev/api/v1",
  omsApiHost: "https://rc-oms-api.orangehealth.dev/api",
  scheduler: "https://rc-scheduler-api.orangehealth.dev/api/v1",
  bugSnag: "d760c9919b600fa0d5ca10e647c9df90",
  agoraId: "8178f71742db4128a07f17fdce9dcf52",
  bugSnagWeb: "3a11fc4989861960eaab2b184816a80c",
  vapidKey: {
    vapidKey:
      "BMP7KYKdlnyraNsxipo0979Y4wWIwQJNXg7uoTkpMR2_9zihxz_6bDRRMyHif84qBeSelkTgQu0eKmaa_u4euPk",
  },
  gcpWebProjectId: "orangehealth-dev-maps",
  gcpWebProjectKey: "AIzaSyAdcKGn1l36A1Unv0wydP-d0kDNCHvFkS8",
  gcpAppAutocompleteKey: "AIzaSyAdcKGn1l36A1Unv0wydP-d0kDNCHvFkS8",
  mixpanelProjectToken: "e0414c91421553a948eab60bb1368ea3",
  fbPixelKey: "",
  mixpanelWebAppProjectToken: "e0414c91421553a948eab60bb1368ea3",
  mixpanelCustomerAppProjectToken: "0f35d51a7cadf84b8ef2b3aeac887b0e",
  mixpanelDoctorAppProjectToken: "be5f58fb9cf48d7786bc91cdc7221084",
  gaTagMangerId: "GTM-N9Z7LRS",
  appsFlyerDevKey: "9ouE4xoaffU2SVdj8yDpJc",
  appsFlyerAppId: "id1534689588",
  customerEmedicBridge: {
    enabled: true,
    bridgeNumber: "+918069454196",
  },
  cleverTapId: "TEST-944-869-R66Z",
  smartLookAppProjectToken: "6ef63c6640a8b53c38bc053e9d7caeb2ee4afd23",
  appName: "OH-Patient",
  appVersion: "4.1.4",
  smartLookKey: "02066dab1ea8d0e2401e87de880932ff24268666",
};
