import * as React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import Loader from '../common/Loader/Loader';
import UnifiedSearch from '../TestsCheckups/unifiedSearch';
const FileViewer = React.lazy(() => import('../FileViewer/FileViewer'));
const Invoice = React.lazy(() => import('../Invoices/Invoice'));
const DoctorReceptionistRoute = React.lazy(() =>
  import('../Operations/DoctorSignUp/Doctor-ReceptionistRoute'),
);
const DoctorSignUp = React.lazy(() =>
  import('../Operations/DoctorSignUp/DoctorSignUp'),
);
const ReceptionistForm = React.lazy(() =>
  import('../Operations/DoctorSignUp/ReceptionistForm'),
);
const Report = React.lazy(() => import('../Report/Report'));
const TestPricing = React.lazy(() => import('../TestPricing/TestPricing'));
const Vitals = React.lazy(() => import('../Vitals/Vitals'));
const OrderTracker = React.lazy(() => import('../Order/OrderTracker'));
const PublicOrder = React.lazy(() => import('./PublicOrder'));
const PublicCamp = React.lazy(() => import('./PublicCamp/PublicCamp'));
const CampEnded = React.lazy(() => import('./CampEnded/CampEnded'));
const PublicOrderSuccess = React.lazy(() => import('./PublicOrderSuccess'));
const SrfForm = React.lazy(() => import('./SrfForm'));
const Tests = React.lazy(() => import('../TestsCheckups/Tests'));
const Checkups = React.lazy(() => import('../TestsCheckups/Checkups'));
const CampCheckups = React.lazy(() => import('./TestsCheckups/CampCheckups'));
const CampTests = React.lazy(() => import('./TestsCheckups/CampTests'));
const B2cPartnerCheckups = React.lazy(() =>
  import('./TestsCheckups/B2cPartnerCheckups'),
);
const CampTestsAndCheckups = React.lazy(() =>
  import('./TestsCheckups/CampTestsAndCheckups'),
);
const PaymentPage = React.lazy(() => import('./PaymentPage'));
const OrderDetails = React.lazy(() => import('./OrderDetails'));

export default function Public() {
  return (
    <React.Suspense
      fallback={
        <div className="component-loader">
          <Loader theme="dark" />
        </div>
      }>
      <Switch>
        <Route path="/public/order" component={PublicOrder} />
        <Route path="/public/camp" component={PublicCamp} />
        <Route path="/public/camp-ended" component={CampEnded} />
        <Route
          path="/public/add-tests-checkups/:tab/:patientId"
          component={UnifiedSearch}
        />
        <Route path="/public/add-tests/:patientId" component={Tests} />

        <Route path="/public/add-checkups/:patientId" component={Checkups} />
        <Route path="/public/add-tests-camp/:patientId" component={CampTests} />
        <Route
          path="/public/add-checkups-camp/:patientId"
          component={CampCheckups}
        />
        <Route
          path="/public/add-tests-and-checkups-camp/:patientId"
          component={CampTestsAndCheckups}
        />
        <Route path="/public/add-partner-checkups" component={Checkups} />
        <Route
          path="/public/add-b2c-partner-checkups"
          component={B2cPartnerCheckups}
        />
        <Route path="/public/add-partner-test" component={Tests} />
        <Route path="/public/order-details" component={OrderTracker} />
        <Route path="/public/order-success" component={PublicOrderSuccess} />
        <Route path="/public/srf-form/:requestId" component={SrfForm} />
        <Route path="/public/payment" component={PaymentPage} />
        <Route path="/public/order-invoices" component={OrderDetails} />

        <Route path="/report/:id" component={Report} />
        <Route path="/vitals/:token" component={Vitals} />
        <Route path="/file" component={FileViewer} />
        <Route
          path="/operations/doctor-sign-up"
          component={DoctorReceptionistRoute}
        />
        <Route path="/test-pricing" component={TestPricing} />
        <Route path="/invoice/:id" component={Invoice} />
        <Route path="/doctor/onboard-form" component={DoctorSignUp} />
        <Route path="/receptionist/onboard-form" component={ReceptionistForm} />

        <Redirect to="/public/order" />
      </Switch>
    </React.Suspense>
  );
}
