import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import getCurrentTime from '../helpers/getCurrentTime';
import {
  auth,
  database,
  storage,
  messaging,
  remoteConfig,
} from './firebaseProvider';
import { forwardMessageCopy } from '../services/userService';

export const resetConnection = () => {
  database().goOffline();
  database().goOnline();
};

resetConnection();

export function getContacts(userId) {
  return database().ref(`/users/${userId}/contacts/`);
}

export function getChatThreads(userId) {
  return database().ref(`/users/${userId}/threads`);
}

export function getChatThread(userId, threadId) {
  return database().ref(`/users/${userId}/threads/${threadId}/lastMessage`);
}

export function getThread(threadId) {
  return database().ref(`/threads/${threadId}`);
}

export function getMessages(threadId, limit) {
  if (limit) {
    return database()
      .ref(`/threads/${threadId}/messages`)
      .orderByKey()
      .limitToLast(limit);
  }
  return database().ref(`/threads/${threadId}/messages`).orderByKey();
}

export function getFirstMessage(threadId) {
  return database()
    .ref(`/threads/${threadId}/messages`)
    .orderByKey()
    .limitToFirst(1);
}

export function getThreadType(threadId) {
  return database().ref(`/threads/${threadId}/threadType`);
}

export function getThreadStatus(threadId) {
  return database().ref(`/threads/${threadId}/status`);
}

export function getThreadUsers(threadId) {
  return database().ref(`/threads/${threadId}/users`);
}

export async function updateMessage(threadId, messageKey, data) {
  if (threadId && messageKey && data) {
    await database()
      .ref(`/threads/${threadId}/messages/${messageKey}`)
      .update(data);
    const updatedMessageSnap = await database()
      .ref(`/threads/${threadId}/messages/${messageKey}`)
      .once('value');
    return updatedMessageSnap.val();
  }
}

export async function pushMessage(users, threadId, messageObj) {
  if (!users || !threadId || !messageObj) {
    return;
  }
  const message = { ...messageObj };
  const time = getCurrentTime();
  const epochTime = getCurrentTime(true);
  message.createdAt = time;
  const messageSnap = await database()
    .ref(`/threads/${threadId}/messages`)
    .push(message);

  const key = messageSnap.key;
  const updatedMessage = await updateMessage(threadId, key, { key });

  // Update thread related data
  let updateObject = {};
  updateObject[`threads/${threadId}/updatedAt`] = epochTime;
  updateObject[`threads/${threadId}/updatedBy`] = message.user._id;
  updateObject[`threads/${threadId}/isRead`] = false;
  for (const uid in users) {
    const userId = parseInt(uid, 10);
    let lastMessage = message.text;
    if (message.type === 'videoCall') {
      lastMessage = 'Video Call invite';
    } else if (message.type === 'voiceCall') {
      lastMessage = 'Voice Call';
    } else if (
      !message.text &&
      `${message.type}`.toLowerCase().includes('image')
    ) {
      lastMessage = 'sent an [image]';
    } else if (!message.text) {
      lastMessage = 'sent an attachment';
    }
    updateObject[`users/${userId}/threads/${threadId}/lastMessage`] =
      lastMessage;
    updateObject[`users/${userId}/threads/${threadId}/updatedAt`] = time;
    // update unread count for all the users in the thread
    try {
      if (userId !== message.user._id) {
        await database()
          .ref(`users/${userId}/threads/${threadId}/unread`)
          .transaction((count) => {
            if (count === null) return 0;
            return count + 1;
          });
      }
    } catch {}
  }
  await database().ref().update(updateObject);
  return updatedMessage;
}

export async function forwardMessage(
  currentMessage,
  currentUserId,
  thread,
  sourceThreadId
) {
  const message = { ...currentMessage };
  const users = {};
  users[currentUserId] = true;
  users[thread.users.userId] = true;
  delete message.key;
  delete message.createdAt;
  delete message._id;
  delete message.visibleTo;
  message._id = uuidv4();
  message.user = {
    _id: currentUserId,
  };
  const newMessage = await pushMessage(users, thread.threadId, message);
  await forwardMessageCopy(thread.threadId, {
    source_thread_id: sourceThreadId,
    destination_message_key: newMessage.key,
  });
}

export async function markThreadAsRead(threadId, userId) {
  try {
    database().ref(`/users/${userId}/threads/${threadId}/`).update({
      unread: 0,
      // reset special icon flags
      isReportAvailable: false,
      isVitalAvailable: false,
      isPrescriptionAvailable: false,
      isPaymentRequested: false,
      isPaymentCompleted: false,
      isOrderInitiated: false,
    });
    const threadSnap = await getThread(threadId).once('value');
    const thread = threadSnap.val();
    if (thread.updatedBy && thread.updatedBy !== userId) {
      database().ref(`threads/${threadId}/`).update({ isRead: true });
    }
  } catch {}
}

export function uploadFileString(threadId, file, fileString) {
  const fileReference = file.fileReference;
  if (!fileReference) {
    console.error('File Reference not found.');
    return;
  }
  const storageRef = storage().ref(`/threads/${threadId}/${fileReference}`);
  return storageRef.putString(fileString, 'base64');
}

export function uploadFile(threadId, file, type) {
  const fileReference = file.fileReference;
  const storageRef = storage().ref(`/threads/${threadId}/${fileReference}`);
  if (!storageRef.putFile) {
    return storageRef.put(file);
  }
  if (type === 'FILE') {
    const filePath = file.uri.replace('file://', '');
    return storageRef.putFile(filePath);
  } else {
    return storageRef.putFile(file.path);
  }
}

export function getFileUrl(ref) {
  return storage().ref(ref).getDownloadURL();
}

export function getContactDetails(userId, threadId) {
  return database().ref(`/users/${userId}/threads/${threadId}/users/`);
}

export async function refreshFirebaseToken() {
  const user = await auth().currentUser;
  if (user) {
    return user.getIdToken(true);
  }
  return undefined;
}

export function getChatMessage(threadId, messageKey) {
  return database().ref(`/threads/${threadId}/messages/${messageKey}`);
}

export async function getFcmToken(callback) {
  const token = callback
    ? await messaging().getToken().then(callback)
    : await messaging().getToken();
  return token || 'FCM Token not found';
}

export async function addVoipToken(userId, voipToken) {
  if (!userId || !voipToken) {
    return;
  }
  const token = await messaging().getToken();
  await database()
    .ref(`users/${userId}/devices/${token}`)
    .update({ voipToken });
  // returns normal notification token
  return token;
}

export function getOrangeHealthThreadId(userId) {
  return database().ref(`/users/${userId}/metadata/orangeThreadId`);
}

export function getCovidTAT() {
  return database().ref(`/appData/patient/covid`);
}

export function getConfigurableData(appType) {
  return database().ref(`/appData/${appType}/`);
}

export function doctorBonusCompletion(userId, shouldUpdate) {
  if (shouldUpdate) {
    const expiryDate = moment().add(3, 'days').toDate();
    return database()
      .ref(`/users/${userId}/metadata/`)
      .update({ newDoctorBonusCardExpiry: expiryDate });
  }
  return database().ref(`/users/${userId}/metadata/newDoctorBonusCardExpiry`);
}

export function getAppStats() {
  return database().ref(`/appData/stats/`);
}

export function getShowReportTat() {
  return database().ref(`/appData/patient/showReportTat`);
}

export function getPublicOrderDetails(orderToken) {
  return database().ref(`/orders/${orderToken}`);
}

// Remote config changes starts

export const fetchAndActivateRemoteConfig = async (
  defaultConfig = {},
  cacheTime = 12 * 60 * 60
) => {
  await remoteConfig()
    .setDefaults(defaultConfig)
    .then(() => remoteConfig().fetch(cacheTime))
    .then(() => remoteConfig().fetchAndActivate())

    .then((fetchedRemotely) => {
      if (fetchedRemotely) {
        console.log(
          '+++Configs were retrieved from the backend and activated.'
        );
        console.log(fetchedRemotely);
      } else {
        console.log(
          '+++++No configs were fetched from the backend, and the local configs were already activated'
        );
      }
    });
};

export const getAllRemoteConfig = () => {
  //Code to get All parameters from Firebase Remote config
  const parameters = remoteConfig().getAll();
  Object.entries(parameters).forEach(($) => {
    const [key, entry] = $;
    console.log('--Key: ', key);
    console.log('--Source: ', entry.getSource());
    console.log('--Value: ', entry.asString());
    console.log('--------------------------------');
  });
};

export async function onTokenRefreshListener(callback) {
  return callback
    ? await messaging().onTokenRefresh(callback)
    : 'No callback found for refresh listener';
}
