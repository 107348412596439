import React from 'react';
import LottieView from 'lottie-react-native';
import { StyleSheet, View, Platform, Text } from 'react-native';
import AppText from '../AppText/AppText';

export default function Loader({ style, message = '' }) {
  return (
    <View style={[styles.loaderWrapper, style]}>
      <LottieView
        style={styles.loader}
        source={require('../../../static/animations/Loader.json')}
        autoPlay
        loop
      />
      <AppText style={styles.textStyle}>{message}</AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  loaderWrapper: {
    display: 'flex',
    height: Platform.OS == 'web' ? '100vh' : '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: 100,
    height: 100,
  },
  textStyle: {
    color: '#3c3c3c',
  },
});
