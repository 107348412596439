import { setItem, USER } from "../helpers/localstorage";

const initialState = {
  has_onboarded:  null,
  isLoggedIn: null,
  addresses: null,
  records: null,
  error: null,
};

export default function user(state = {
  ...initialState,
}, action) {
  switch (action.type) {
    case 'UPDATE_USER':
      const updatedState = {
        ...state,
        ...action.user,
        isLoggedIn: true,
        error: null,
      };
      setItem(USER, updatedState);
      return updatedState;
    case 'LOGOUT_USER':
      return {
        isLoggedIn: false,
        has_onboarded:  null,
        error: null,
      };
    case 'USER_ERROR': 
      return {
        ...state,
        error: action.error,
      }
    case 'CLEAR_USER_ERROR': 
      return {
        ...state,
        error: null,
      }
    default:
      return state;
  }
}
