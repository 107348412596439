import { Linking } from "react-native";

import { orangeWANumber, orangeWANumberForDoctor } from "../constants/common";
import toastAlertMessage from "./toastAlertMessage";

export default function redirectToWhatsapp(text, userType) {
  const WANumber = userType === 'doctor' ? orangeWANumberForDoctor : orangeWANumber;
  const whatsappUniversalLink = new URL(`https://wa.me/${WANumber}`);
  whatsappUniversalLink.searchParams.append('text', text);
  try {
    Linking.openURL(whatsappUniversalLink.toString());
  } catch {
    toastAlertMessage("Couldn't open whatsapp");
  }
}
