import React, {useEffect, useState} from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  LayoutAnimation,
  Platform,
  UIManager,
  Image,
  Dimensions,
} from 'react-native';

import AppText from '../../../../../shared/components/common/AppText/AppText';
import If from '../If/If';

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export default function TextAccordion({
  linearGradientComponent: LinearGradient,
  containerStyles,
  content,
  unExpandedContentWidth = '80%',
}) {
  const maxWidthContainer = Dimensions.get('window').width - 38;
  const [isExpanded, updateIsExpanded] = useState(false);
  const [isReadMoreRequired, updateReadMoreRequired] = useState(true);
  const toggleExpand = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    updateIsExpanded(currentlyExpanded => !currentlyExpanded);
  };

  useEffect(() => {
    if (content && content.length > 30) {
      updateReadMoreRequired(true);
    } else {
      updateReadMoreRequired(false);
    }
  }, [content]);

  return (
    <View
      style={[
        styleSheets.root,
        containerStyles ? {...containerStyles} : {},
        Platform.OS === 'web'
          ? {
              ...Platform.select({
                web: {
                  maxWidth: maxWidthContainer,
                },
              }),
            }
          : {},
      ]}>
      <View style={styles.infoIconContainer}>
        <Image
          style={styles.infoIcon}
          source={require('../../../static/images/info-fill.png')}
        />
      </View>
      <TouchableOpacity
        disabled={!isReadMoreRequired}
        style={styles.pressable}
        onPress={toggleExpand}>
        <View style={styles.accordionWrapper}>
          <View
            style={[
              styles.contentWrapper,
              isExpanded ? styles.contentWrapperExtended : {},
            ]}>
            <View style={styles.titleRow}>
              <View
                style={[
                  styles.titleTextContainer,
                  isExpanded
                    ? styles.titleTextContainerExpanded
                    : {
                        width: unExpandedContentWidth,
                      },
                ]}>
                <View
                  style={[
                    isExpanded && styles.titleRowExpanded,
                    styles.contentContainer,
                  ]}>
                  <AppText
                    numberOfLines={isExpanded ? 10 : 1}
                    onTextLayoutChange
                    ellipsizeMode="tail"
                    key="content"
                    containerStyles={{
                      width: '100%',
                    }}
                    style={{
                      ...styles.contentText,
                      ...(isExpanded ? styles.contextTextExpanded : {}),
                      ...(maxWidthContainer && Platform.OS === 'web'
                        ? Platform.select({
                            web: {
                              maxWidth: maxWidthContainer - 100,
                            },
                          })
                        : {}),
                    }}>
                    {content}
                  </AppText>
                  <If
                    condition={isExpanded}
                    render={() => (
                      <View>
                        <TouchableOpacity onPress={toggleExpand}>
                          <AppText style={styles.buttonText}>Read less</AppText>
                        </TouchableOpacity>
                      </View>
                    )}
                  />
                </View>
              </View>
              <If
                condition={!isExpanded && isReadMoreRequired}
                render={() => (
                  <View style={styles.readMoreButtonContainer}>
                    <AppText style={styles.buttonText}>Read More</AppText>
                  </View>
                )}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styleSheets = StyleSheet.create({
  root: {
    backgroundColor: '#f4f4f4',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'flex-start',
  },
});

const styles = {
  pressable: {justifyContent: 'center', flex: 1},
  accordionWrapper: {flexDirection: 'row'},
  contentWrapper: {
    flex: 1,
    justifyContent: 'center',
    maxHeight: 20,
  },
  contentWrapperExtended: {
    maxHeight: 200,
  },
  titleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleRowExpanded: {
    flexDirection: 'column',
  },
  title: {
    fontSize: 16,
    fontWeight: Platform.OS === 'web' ? 'bold' : '600',
  },
  titleTextContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    overflow: 'hidden',
  },
  titleTextContainerExpanded: {
    flex: 1,
    justifyContent: 'flex-start',
    marginRight: 5,
  },
  infoIconContainer: {
    marginRight: 12,
  },
  infoIcon: {
    width: 18,
    height: 18,
  },
  contentUnexpanded: {
    width: '100%',
    overflow: 'hidden',
  },
  subtitle: {
    color: '#616161',
    fontSize: 10,
    fontWeight: '600',
  },
  content: {paddingBottom: 0, paddingTop: 0},
  contentText: {
    color: '#53596c',
    fontSize: 12,
    fontFamily: 'montserrat-medium',
    lineHeight: 16,
  },
  contextTextExpanded: {
    width: '100%',
  },
  buttonText: {
    color: '#53596c',
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'montserrat-semibold',
    ...Platform.select({
      web: {
        fontWeight: 600,
      },
    }),
  },
  contentContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  readMoreButtonContainer: {
    justifyContent: 'flex-end',
  },
  icon: {
    ...Platform.select({
      native: {
        width: 50,
        height: 50,
      },
      web: {
        width: 30,
        height: 30,
      },
    }),
  },
  iconFlipped: {
    ...Platform.select({
      native: {
        width: 50,
        height: 50,
      },
      web: {
        width: 30,
        height: 30,
      },
    }),
    transform: [{rotate: '180deg'}],
  },
};
