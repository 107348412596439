import React from 'react';
import {StyleSheet, View} from 'react-native';
import semiBoldFontConverter from '../../../../../shared/helpers/semiBoldFontConverter';
import If from '../../common/If/If';
import TextAccordion from '../../common/TextAccordion/TextAccordion';
import ReportIcon from '../../../static/images/report_time_icon.png';
import GroupTestIcon from '../../../static/images/groupTestIcon.png';
import FastIcon from '../../../static/images/fast.png';
import Arrow from '../../../static/images/arrow.png';
import '../Tests.scss';

function RenderTestItem(props) {
  const {
    selectedTests,
    renderTAT,
    onPressSelect,
    renderGroupTests,
    searchTerm,
    updateShowGroupTestsModal,
    showGroupTestModal,
    test,
  } = props;

  const isSelected = selectedTests?.findIndex(t => t.id === test.id) > -1;
  return (
    <>
      <View style={styles.test}>
        <View style={styles.selection}>
          <div key="name" className="testText">
            {test.testName}
          </div>
          <div onClick={() => onPressSelect(test)}>
            <If
              condition={isSelected}
              render={() => (
                <div className="addedButton">
                  <div className="addedText">Added</div>
                </div>
              )}
            />
            <If
              condition={!isSelected}
              render={() => (
                <div className="addButton">
                  <div className="addText">+ Add</div>
                </div>
              )}
            />
          </div>
        </View>

        <div className="testMeta">
          <div className="textRow">
            <If
              condition={renderTAT(test.labTat + test.logisticTat)}
              render={() => (
                <>
                  <div key="category" className="category">
                    <img
                      src={ReportIcon}
                      alt="report icon"
                      className="reportTimeIcon"
                    />
                    Reports within{' '}
                    {renderTAT(
                      parseInt(test?.labTat || 0) +
                        parseInt(test?.logisticTat || 0),
                    )}
                  </div>
                </>
              )}
            />
          </div>
          <div className="textRowPrice">
            <div key="price" className="rupee">
              &#8377;&nbsp;
            </div>
            <div key="amount" className="amount">
              {test?.consumerPrice}
            </div>
          </div>
        </div>

        <div>
          <If
            condition={test?.groupTests?.length}
            render={() => (
              <div
                className={test.groupTests.length > 3 && 'showPointer'}
                onClick={() =>
                  test.groupTests.length > 3 && updateShowGroupTestsModal(test)
                }>
                <div className="divider" />
                <div className="row">
                  <div className="col-1">
                    <img src={GroupTestIcon} className="arrow" />
                  </div>
                  <div className="col-2">
                    <span className="groupTestsList">
                      {test.groupTests.length} test
                      {test.groupTests.length === 1 ? '' : 's'}:
                    </span>
                    {renderGroupTests(test.groupTests, searchTerm)}
                    <If
                      condition={test.groupTests.length > 3}
                      render={() => (
                        <span className="groupTestText">
                          and {test.groupTests.length - 3} other test
                          {test.groupTests.length - 3 === 1 ? '' : 's'}
                          {'   '}
                        </span>
                      )}
                    />
                  </div>
                </div>
                <div className="viewAllTestLayout">
                  <If
                    condition={test.groupTests.length > 3}
                    render={() => (
                      <div
                        onClick={() => updateShowGroupTestsModal(test)}
                        className="viewAllTestsContainer">
                        <div className="viewAllTests">View all tests</div>

                        <img src={Arrow} alt="arrow" className="testArrow" />
                      </div>
                    )}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <If
          condition={test?.instructionInfo}
          render={() => (
            <>
              <If
                condition={
                  test.instructionInfo?.tags &&
                  Array.isArray(test.instructionInfo?.tags) &&
                  test.instructionInfo?.tags.length > 0
                }
                render={() =>
                  test.instructionInfo.tags.map((tag, tagIndex) => (
                    <div>
                      <div className="divider" />
                      <div key="category" className="category">
                        <img src={FastIcon} className="arrow" />
                        <div key={`${tagIndex}_${tag}`} className="fastText">
                          {tag}
                        </div>
                        {renderTAT(
                          parseInt(showGroupTestModal?.labTat || 0) +
                            parseInt(showGroupTestModal?.logisticTat || 0),
                        )}
                      </div>
                    </div>
                  ))
                }
              />
            </>
          )}
        />
        <If
          condition={test.instructionInfo?.note}
          render={() => (
            <div className="note">
              <TextAccordion
                containerStyles={testInfoStyles}
                content={test.instructionInfo?.note}
                unExpandedContentWidth="80%"
              />
            </div>
          )}
        />
      </View>
    </>
  );
}

export default RenderTestItem;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#fff6ea',
    opacity: 1,
    backgroundSize: '7px 7px',
    backgroundImage:
      'repeating-linear-gradient(45deg, rgb(255 255 255 / 54%) 0px, rgb(142 142 142 / 26%) 0.7px, rgb(255, 246, 234) 0px, rgb(255, 246, 234) 50%)',
  },
  header: {
    paddingHorizontal: 25,
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerUpper: {
    backgroundColor: '#eceee6',
    paddingBottom: '47px',
  },
  searchContainer: {
    marginTop: '-20px',
  },
  heading: {
    color: '#000',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 12,
    marginBottom: 3,
    textTransform: 'uppercase',
  },
  addressPin: {
    width: 11,
    height: 11,
    marginRight: 2,
    marginLeft: 12,
  },
  subHeadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subHeadingTextContainer: {
    flexDirection: 'row',
  },
  subHeadingGreenText: {
    fontSize: 10,
    fontWeight: '600',
    color: '#01b37a',
    textTransform: 'uppercase',
    fontFamily: 'montserrat-semibold',
  },
  subHeadingGreyText: {
    fontSize: 10,
    fontWeight: '600',
    color: '#7d839a',
    textTransform: 'uppercase',
  },
  backButton: {
    width: 44,
    height: 44,
    marginTop: 5,
  },
  test: {
    paddingTop: 16,
    paddingBottom: 18,
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: '#fff',
    minHeight: 80,
    marginHorizontal: 20,
    marginBottom: 16,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },

  testMeta: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  testText: {
    color: '#3c3c3c',
    fontSize: 14,
    fontWeight: '500',
    minWidth: '80%',
    lineHeight: 18,
    fontFamily: 'montserrat-semibold',
  },
  category: {
    color: '#7d839a',
    fontSize: 11,
  },
  categoryText: {
    color: '#7d839a',
    fontSize: 11,
    fontFamily: 'montserrat-bold',
  },
  rupee: {
    color: '#7d839a',
    fontSize: 12,
    fontWeight: '500',
  },
  amount: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#53596c',
  },
  textRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderBtnWrapper: {
    height: 65,
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 15,
    width: '88%',
    maxWidth: 460,
    bottom: 0,
    position: 'absolute',
  },
  bookButtonWrapper: {
    width: '100%',
    height: 70,
    alignSelf: 'center',
    marginVertical: 24,
  },
  bookButtonContentWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  bookButtonAnimation: {
    width: 72,
    height: 72,
  },
  bookButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'montserrat-bold',
    paddingLeft: 11,
  },
  bookButtonSubText: {
    color: '#fff',
    fontSize: 14,
    paddingLeft: 11,
  },
  checkBoxIcon: {
    width: 24,
    height: 24,
    marginLeft: 14,
  },
  selection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tag: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    flexDirection: 'row',
    marginTop: 10,
    alignSelf: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#f6f0fd',
    borderRadius: 12,
  },
  tagText: {
    fontSize: 12,
    color: '#750ea3',
    marginRight: 2,
    ...semiBoldFontConverter(600),
  },
  tagIcon: {
    width: 10,
    height: 10,
    marginTop: 1,
  },
  cantFindTestsCard: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#ffe8ca',
    backgroundColor: '#fff',
    width: '85%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  cantFindTestsCardTitle: {
    fontSize: 14,
    lineHeight: 18,
    textAlign: 'center',
    color: '#3c3c3c',
    marginBottom: 10,
    fontFamily: 'montserrat-bold',
  },
  cantFindTestsCardSubtext: {
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 16,
    textAlign: 'center',
    color: '#616161',
    marginBottom: 10,
  },
  cantFindTestsCTA: {
    paddingHorizontal: 34,
    paddingVertical: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ff970a',
    backgroundColor: '#fff',
    flex: 1,
  },
  cantFindTestsCTAText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#ff970a',
  },
  notFound: {
    marginVertical: 30,
    flexDirection: 'column',
    alignItems: 'center',
    width: '70%',
  },
  notFoundTitle: {
    fontSize: 14,
    color: '#616161',
    marginBottom: 10,
    fontFamily: 'montserrat-bold',
  },
  notFoundSubTitle: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'center',
    color: '#616161',
  },
  groupTestsList: {
    color: '#62697B',
    fontSize: 12,
    paddingBottom: 5,
  },
  ViewTestscontainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  numGrpTests: {
    fontSize: 12,
    paddingTop: 1,
  },
  viewAllTests: {
    color: 'green',
    textDecoration: 'underline',
    fontSize: 12,
  },
  closeBtnWrapper: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 999,
  },
  closeBtnIcon: {
    width: 24,
    height: 24,
  },
  selectTestBtnWrapper: {
    height: 65,
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 15,
    width: '100%',
    bottom: 0,
  },
});

const testInfoStyles = {
  borderRadius: 8,
  backgroundColor: '#fffaeb',
  paddingHorizontal: 12,
  alignItems: 'center',
  paddingVertical: 12,
};
