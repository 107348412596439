import firebaseConfig from '../config/firebase.config';

import firebaseApp from 'firebase/app';
import "firebase/auth";
import "firebase/storage";
import "firebase/messaging";
import "firebase/database";
import "firebase/remote-config";
import "firebase/performance";


firebaseApp.initializeApp(firebaseConfig);

const messaging = firebaseApp.messaging;
const database = firebaseApp.database;
const storage = firebaseApp.storage;
const remoteConfig=firebaseApp.remoteConfig;
const auth = firebaseApp.auth;
const perf = firebaseApp.performance();

export {
  database,
  storage,
  messaging,
  auth,
  remoteConfig,
  perf,
}