import { ToastAndroid, Alert, Platform } from 'react-native';

const toastAlertMessage = (message, type) => {
    if (Platform.OS === 'android') {
        ToastAndroid.show(message, type || ToastAndroid.LONG)
    } else {
        Alert.alert(message);
    }
}

export default toastAlertMessage;
