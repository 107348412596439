import React from 'react';
import Lottie from 'react-lottie';

import animationData from '../../../static/animations/Loader.json';
import './Loader.scss';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function Loader() {
  return (
    <div className="loaderWrapper">
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  );
}
