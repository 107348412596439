import React, { forwardRef } from 'react';
import { TextInput } from 'react-native';

import elevationShadowStyle from '../../../helpers/shadowElevation';

function AppInput(props, ref) {
  const styling = {
    ...style.textInput,
    ...(props.multiline ? { minHeight: 55 } : { height: 55 }),
    ...(props.disabled
      ? {
          backgroundColor: '#F4F4F4',
          borderColor: '#CFCFCF',
          borderWidth: 1,
        }
      : {
          ...elevationShadowStyle(3),
        }),
    ...(props.width ? { width: props.width } : {}),
    ...(props.noShadow
      ? {
          ...elevationShadowStyle(0),
          borderRadius: 8,
          borderWidth: 1,
          borderColor: '#e5e5e5',
          ...props.style,
        }
      : { ...props.style }),
  };
  return (
    <TextInput
      {...props}
      ref={ref}
      placeholderTextColor={props?.placeholderTextColor || '#616161'}
      style={styling}
    />
  );
}

export default forwardRef(AppInput);

const style = {
  textInput: {
    width: '90%',
    backgroundColor: '#fff',
    borderRadius: 8,
    paddingHorizontal: 24,
    paddingVertical: 12,
    shadowColor: '#0f0f0f',
    fontFamily: 'montserrat-medium',
    color: '#000',
  },
};
