export default function master(state = {
  cities: [],
  serviceableCities: [],
}, action) {
  switch (action.type) {
    case 'ADD_CITIES': {
      return { ...state, ...{ cities: action.cities } };
    }
    case 'SHOW_PERMISSION_MODAL':
      return { ...state, permissionModal: { ...action.payload } };
    case 'RESET_PERMISSION_MODAL':
      return { ...state, permissionModal: null };
    case 'ADD_SERVICEABLE_CITIES': {
      return { ...state, ...{ serviceableCities: action.cities } };
    }
    default:
      return state;
  }
}
