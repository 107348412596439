import { Platform } from 'react-native';
import Bugsnag from '@bugsnag/react-native';

import {
  APP_TYPE,
  AUTH_TOKEN,
  clearLocalStorage,
  getItem,
  REFRESH_TOKEN,
  setItem,
  USER,
} from '../helpers/localstorage';
import { getUser } from '../services/authService';
import { auth, database, messaging } from '../services/firebaseProvider';
import { clearAllCookies } from '../helpers/authhelpers';
import { refreshToken, validateAndRefreshToken } from '../helpers/jwtHelpers';
import { getConfig } from '../config/config';
import { refreshFirebaseToken } from '../services/firebaseService';

const vapidKey = Platform.OS === 'web' ? getConfig().vapidKey : undefined;

export async function updateMessagingToken(userId) {
  try {
    if (!userId) return;
    if (Platform.OS === 'web') return;
    const token = await messaging().getToken(vapidKey);
    if (!token) return;
    const ref = await database().ref(`users/${userId}/devices`);
    ref.keepSynced(true);
    const appType = await getItem(APP_TYPE);
    await ref.transaction((devices) => {
      const newDevices = devices || {};
      const device = newDevices[token] || {};
      for (let deviceKey in newDevices) {
        if (
          !newDevices[deviceKey].hasOwnProperty('deviceType') &&
          token === newDevices[deviceKey]
        ) {
          delete newDevices[deviceKey];
        }
      }
      const deviceType = Platform.OS;
      newDevices[token] = {
        ...device,
        deviceType,
        appType,
      };
      return newDevices;
    });
  } catch {}
}

export async function removeMessagingToken(userId) {
  try {
    if (Platform.OS === 'web') return;
    const token = await messaging().getToken(vapidKey);
    await database()
      .ref(`users/${userId}/devices`)
      .transaction((devices) => {
        const newDevices = devices || {};
        for (const key in newDevices) {
          if (key === token) {
            delete newDevices[key];
          }
        }
        return newDevices;
      });
  } catch {}
}

async function requestPermission() {
  try {
    if (Platform.OS !== 'web') {
      const granted = await messaging().requestPermission({
        alert: true,
        badge: true,
        sound: true,
      });
      if (granted) return true;
      return false;
    }
  } catch (err) {
    // known error in firebase
    // https://github.com/firebase/firebase-js-sdk/issues/2364#issuecomment-570820017
  }
}

auth().onAuthStateChanged(async (user) => {
  try {
    const permissionGranted = await requestPermission();
    if (!permissionGranted) return;
    const isOpsWeb =
      Platform.OS === 'web'
        ? window.location.href.includes('/operations/doctor-sign-up')
        : false;
    if (user) {
      await updateMessagingToken(user.uid);
    } else if (!isOpsWeb) {
      if (Platform.OS === 'web') {
        const u = await getItem(USER);
        if (u) {
          await removeMessagingToken(u.user_id);
        }

        await clearLocalStorage();
        clearAllCookies();
      }
    }
  } catch (e) {
    console.error(e);
  }
});

export function loginAction(user) {
  return async (dispatch) => {
    if (!user || !user.access) {
      return;
    }
    await loginIntoFirebase(user.custom_token).catch((err) => {
      dispatch(logoutUser());
      throw err;
    });
    await setItem(AUTH_TOKEN, user.access);
    await setItem(REFRESH_TOKEN, user.refresh);
    await dispatch(getUserDetails());
    dispatch({
      type: 'UPDATE_USER',
      user,
    });
  };
}

export function logoutAction() {
  return {
    type: 'LOGOUT_USER',
  };
}

export function updateUser(user) {
  return {
    type: 'UPDATE_USER',
    user,
  };
}

export function logoutUser() {
  return async (dispatch) => {
    await clearLocalStorage();
    dispatch(logoutAction());
  };
}

export function refreshTokenAction() {
  return async (dispatch) => {
    const token = await refreshFirebaseToken();
    if (!token) {
      dispatch(logoutUser());
    }
  };
}

// https://firebase.google.com/docs/reference/js/firebase.auth.Auth#error-codes_11
export async function loginIntoFirebase(customToken) {
  const user = auth().currentUser;
  if (!user && customToken) {
    return await auth()
      .signInWithCustomToken(customToken)
      .catch(function (error) {
        const errorCode = error.code;
        if (errorCode === 'auth/invalid-custom-token') {
          refreshToken();
        }
      });
  }
}

export async function logoutFirebase() {
  return auth().signOut();
}

export function getUserDetails() {
  return async (dispatch) => {
    await getUser()
      .then((res) => {
        Bugsnag.setUser(`${res.result.id}`);
        dispatch(updateUser(res.result));
      })
      .catch((err) => {
        if (err.status === 401) {
          dispatch(logoutUser());
        }
      });
  };
}

export function validateAndRefreshTokenAction() {
  return async (dispatch) => {
    const token = await validateAndRefreshToken(dispatch);
    return token;
  };
}
