export const paginatedItemsFetchLimit = 40;
export const orangeWANumber = '919008111144';
export const orangeWANumberForDoctor = '919606060880';
export const enableForceUpdate = false;
export const accordionLabel = 'share-reports-accordion';
export const genderOptions = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
export const relationshipOptions = [
  { value: 'spouse', label: 'Spouse' },
  { value: 'child', label: 'Child' },
  { value: 'parent', label: 'Parent' },
  { value: 'grandparent', label: 'Grandparent' },
  { value: 'sibling', label: 'Sibling' },
  { value: 'others', label: 'Others' },
];

export const B2B_ORDER_STATUS = [
  '',
  'requested',
  'ordered',
  'done not sent',
  '',
  'cancelled',
  'completed',
];

export const ORDER_PAYMENT_STATUS = [
  'PaymentNotIssued',
  'PaymentIssued',
  'PaymentPaid',
  'PaymentPartiallyPaid',
  'PaymentCancelled',
  'PaymentExpired',
];

export const DOCTOR_SUPPORT_MAIL = 'doctors@orangehealth.in';

const defaultCordinates = {
  blr: {
    latitude: 12.9380548,
    longitude: 77.6265529,
  },
  ggn: {
    latitude: 28.4595,
    longitude: 77.0266,
  },
  noa: {
    latitude: 28.5354,
    longitude: 77.391,
  },
};

export function getDefaultCordinates(city) {
  switch (city?.name?.toLowerCase()) {
    case 'bengaluru':
    case 'bangalore':
      return defaultCordinates.blr;
    case 'noida':
      return defaultCordinates.noa;
    case 'gurugram':
    case 'gurgaon':
      return defaultCordinates.ggn;
    default:
      return defaultCordinates.blr;
  }
}

export const TestParameters = {
  TEST: 'tests',
  LIMIT: 50,
};

export const CANCELLATION_REASONS = {
  'out of coverage in bangalore': 'Out of coverage area',
  'from another city': 'Out of coverage area',
  'area/city not serviceable by orange health':
    'Area/city not serviceable by Orange health',
  'customer not responding': "Customer didn't respond",
  'customer finds prices too high': "Canceled as per customer's request",
  'customer finds tat too high': "Canceled as per customer's request",
  'customer wanted earlier slot': "Canceled as per customer's request",
  'customer wanted later slot': "Canceled as per customer's request",
  'customer wants to decide later': "Canceled as per customer's request",
  'customer got the test done elsewhere':
    'Customer got the test done elsewhere',
  'poor service - delay in reaching': "Canceled as per customer's request",
  'poor service - delay in test results': "Canceled as per customer's request",
  'poor service - delay in test results': "Canceled as per customer's request",
  'test not available with orange health':
    'Test not available with Orange Health',
  'other reasons': "Canceled as per customer's request",
  'duplicate order': 'Duplicate Order',
  testing: 'Testing',
  '': "Canceled as per customer's request",
};

export function getOrderKey(reqId) {
  return `request-address-${reqId}`;
}

export const USER_TYPES = {
  doctor: 'e942d4ad24e9789c4b1ff1022e7e52f5',
  customer: '2bcfcf336f6702965f829a3d3d21bf34',
};

export const GENDER_MAP = {
  m: {
    label: 'Male',
    value: 'male',
  },
  f: {
    label: 'Female',
    value: 'female',
  },
  o: {
    label: 'Other',
    value: 'other',
  },
};

export const genderShortForm = {
  male: 'm',
  female: 'f',
};

export const addressProofs = [
  {
    label: 'Aadhaar',
    value: 'aadharNumber',
  },
  {
    label: 'Passport',
    value: 'passportNumber',
  },
  {
    label: 'Driving License',
    value: 'drivingLicense',
  },
  {
    label: 'Other',
    value: 'otherProofNumber',
  },
];

export const addressProofFileTypes = [
  'aadharNumber',
  'passportNumber',
  'drivingLicense',
  'otherProofNumber',
  'formC',
];

const COUNTRIES = [
  {
    country: 'India',
    iso3166_alpha2_code: 'IN',
    is_nationality: true,
    nationality_code: 'IN',
  },
  {
    country: 'Andorra',
    iso3166_alpha2_code: 'AD',
    is_nationality: true,
    nationality_code: 'AD',
  },
  {
    country: 'United Arab Emirates',
    iso3166_alpha2_code: 'AE',
    is_nationality: true,
    nationality_code: 'AE',
  },
  {
    country: 'Afghanistan',
    iso3166_alpha2_code: 'AF',
    is_nationality: true,
    nationality_code: 'AF',
  },
  {
    country: 'Antigua and Barbuda',
    iso3166_alpha2_code: 'AG',
    is_nationality: true,
    nationality_code: 'AG',
  },
  {
    country: 'Anguilla',
    iso3166_alpha2_code: 'AI',
    is_nationality: true,
    nationality_code: 'AI',
  },
  {
    country: 'Albania',
    iso3166_alpha2_code: 'AL',
    is_nationality: true,
    nationality_code: 'AL',
  },
  {
    country: 'Armenia',
    iso3166_alpha2_code: 'AM',
    is_nationality: true,
    nationality_code: 'AM',
  },
  {
    country: 'Angola',
    iso3166_alpha2_code: 'AO',
    is_nationality: true,
    nationality_code: 'AO',
  },
  {
    country: 'Antarctica',
    iso3166_alpha2_code: 'AQ',
    is_nationality: true,
    nationality_code: 'AQ',
  },
  {
    country: 'Argentina',
    iso3166_alpha2_code: 'AR',
    is_nationality: true,
    nationality_code: 'AR',
  },
  {
    country: 'American Samoa',
    iso3166_alpha2_code: 'AS',
    is_nationality: true,
    nationality_code: 'AS',
  },
  {
    country: 'Austria',
    iso3166_alpha2_code: 'AT',
    is_nationality: true,
    nationality_code: 'AT',
  },
  {
    country: 'Australia',
    iso3166_alpha2_code: 'AU',
    is_nationality: true,
    nationality_code: 'AU',
  },
  {
    country: 'Aruba',
    iso3166_alpha2_code: 'AW',
    is_nationality: true,
    nationality_code: 'AW',
  },
  {
    country: 'Aland Islands',
    iso3166_alpha2_code: 'AX',
    is_nationality: true,
    nationality_code: 'AX',
  },
  {
    country: 'Azerbaijan',
    iso3166_alpha2_code: 'AZ',
    is_nationality: true,
    nationality_code: 'AZ',
  },
  {
    country: 'Bosnia and Herzegovina',
    iso3166_alpha2_code: 'BA',
    is_nationality: true,
    nationality_code: 'BA',
  },
  {
    country: 'Barbados',
    iso3166_alpha2_code: 'BB',
    is_nationality: true,
    nationality_code: 'BB',
  },
  {
    country: 'Bangladesh',
    iso3166_alpha2_code: 'BD',
    is_nationality: true,
    nationality_code: 'BD',
  },
  {
    country: 'Belgium',
    iso3166_alpha2_code: 'BE',
    is_nationality: true,
    nationality_code: 'BE',
  },
  {
    country: 'Burkina Faso',
    iso3166_alpha2_code: 'BF',
    is_nationality: true,
    nationality_code: 'BF',
  },
  {
    country: 'Bulgaria',
    iso3166_alpha2_code: 'BG',
    is_nationality: true,
    nationality_code: 'BG',
  },
  {
    country: 'Bahrain',
    iso3166_alpha2_code: 'BH',
    is_nationality: true,
    nationality_code: 'BH',
  },
  {
    country: 'Burundi',
    iso3166_alpha2_code: 'BI',
    is_nationality: true,
    nationality_code: 'BI',
  },
  {
    country: 'Benin',
    iso3166_alpha2_code: 'BJ',
    is_nationality: true,
    nationality_code: 'BJ',
  },
  {
    country: 'Saint Barthélemy',
    iso3166_alpha2_code: 'BL',
    is_nationality: false,
    nationality_code: 'FR',
  },
  {
    country: 'Bermuda',
    iso3166_alpha2_code: 'BM',
    is_nationality: true,
    nationality_code: 'BM',
  },
  {
    country: 'Brunei',
    iso3166_alpha2_code: 'BN',
    is_nationality: true,
    nationality_code: 'BN',
  },
  {
    country: 'Bolivia',
    iso3166_alpha2_code: 'BO',
    is_nationality: true,
    nationality_code: 'BO',
  },
  {
    country: 'Bonaire, Sint Eustatius and Saba',
    iso3166_alpha2_code: 'BQ',
    is_nationality: false,
    nationality_code: 'NL',
  },
  {
    country: 'Brazil',
    iso3166_alpha2_code: 'BR',
    is_nationality: true,
    nationality_code: 'BR',
  },
  {
    country: 'Bahamas',
    iso3166_alpha2_code: 'BS',
    is_nationality: true,
    nationality_code: 'BS',
  },
  {
    country: 'Bhutan',
    iso3166_alpha2_code: 'BT',
    is_nationality: true,
    nationality_code: 'BT',
  },
  {
    country: 'Bouvet Island',
    iso3166_alpha2_code: 'BV',
    is_nationality: true,
    nationality_code: 'BV',
  },
  {
    country: 'Botswana',
    iso3166_alpha2_code: 'BW',
    is_nationality: true,
    nationality_code: 'BW',
  },
  {
    country: 'Belarus',
    iso3166_alpha2_code: 'BY',
    is_nationality: true,
    nationality_code: 'BY',
  },
  {
    country: 'Belize',
    iso3166_alpha2_code: 'BZ',
    is_nationality: true,
    nationality_code: 'BZ',
  },
  {
    country: 'Canada',
    iso3166_alpha2_code: 'CA',
    is_nationality: true,
    nationality_code: 'CA',
  },
  {
    country: 'Cocos (Keeling) Islands',
    iso3166_alpha2_code: 'CC',
    is_nationality: true,
    nationality_code: 'CC',
  },
  {
    country: 'Democratic Republic of the Congo (DCR)',
    iso3166_alpha2_code: 'CD',
    is_nationality: true,
    nationality_code: 'CD',
  },
  {
    country: 'Central African Republic',
    iso3166_alpha2_code: 'CF',
    is_nationality: true,
    nationality_code: 'CF',
  },
  {
    country: 'Congo',
    iso3166_alpha2_code: 'CG',
    is_nationality: true,
    nationality_code: 'CG',
  },
  {
    country: 'Switzerland',
    iso3166_alpha2_code: 'CH',
    is_nationality: true,
    nationality_code: 'CH',
  },
  {
    country: "Côte d'Ivoire (Ivory Coast)",
    iso3166_alpha2_code: 'CI',
    is_nationality: true,
    nationality_code: 'CI',
  },
  {
    country: 'Cook Islands',
    iso3166_alpha2_code: 'CK',
    is_nationality: true,
    nationality_code: 'CK',
  },
  {
    country: 'Chile',
    iso3166_alpha2_code: 'CL',
    is_nationality: true,
    nationality_code: 'CL',
  },
  {
    country: 'Cameroon',
    iso3166_alpha2_code: 'CM',
    is_nationality: true,
    nationality_code: 'CM',
  },
  {
    country: 'China',
    iso3166_alpha2_code: 'CN',
    is_nationality: true,
    nationality_code: 'CN',
  },
  {
    country: 'Colombia',
    iso3166_alpha2_code: 'CO',
    is_nationality: true,
    nationality_code: 'CO',
  },
  {
    country: 'Costa Rica',
    iso3166_alpha2_code: 'CR',
    is_nationality: true,
    nationality_code: 'CR',
  },
  {
    country: 'Cuba',
    iso3166_alpha2_code: 'CU',
    is_nationality: true,
    nationality_code: 'CU',
  },
  {
    country: 'Cape Verde',
    iso3166_alpha2_code: 'CV',
    is_nationality: true,
    nationality_code: 'CV',
  },
  {
    country: 'Curaçao',
    iso3166_alpha2_code: 'CW',
    is_nationality: false,
    nationality_code: 'NL',
  },
  {
    country: 'Christmas Island',
    iso3166_alpha2_code: 'CX',
    is_nationality: true,
    nationality_code: 'CX',
  },
  {
    country: 'Cyprus',
    iso3166_alpha2_code: 'CY',
    is_nationality: true,
    nationality_code: 'CY',
  },
  {
    country: 'Czech Republic',
    iso3166_alpha2_code: 'CZ',
    is_nationality: true,
    nationality_code: 'CZ',
  },
  {
    country: 'Germany',
    iso3166_alpha2_code: 'DE',
    is_nationality: true,
    nationality_code: 'DE',
  },
  {
    country: 'Djibouti',
    iso3166_alpha2_code: 'DJ',
    is_nationality: true,
    nationality_code: 'DJ',
  },
  {
    country: 'Denmark',
    iso3166_alpha2_code: 'DK',
    is_nationality: true,
    nationality_code: 'DK',
  },
  {
    country: 'Dominica',
    iso3166_alpha2_code: 'DM',
    is_nationality: true,
    nationality_code: 'DM',
  },
  {
    country: 'Dominican Republic',
    iso3166_alpha2_code: 'DO',
    is_nationality: true,
    nationality_code: 'DO',
  },
  {
    country: 'Algeria',
    iso3166_alpha2_code: 'DZ',
    is_nationality: true,
    nationality_code: 'DZ',
  },
  {
    country: 'Ecuador',
    iso3166_alpha2_code: 'EC',
    is_nationality: true,
    nationality_code: 'EC',
  },
  {
    country: 'Estonia',
    iso3166_alpha2_code: 'EE',
    is_nationality: true,
    nationality_code: 'EE',
  },
  {
    country: 'Egypt',
    iso3166_alpha2_code: 'EG',
    is_nationality: true,
    nationality_code: 'EG',
  },
  {
    country: 'Western Sahara',
    iso3166_alpha2_code: 'EH',
    is_nationality: true,
    nationality_code: 'EH',
  },
  {
    country: 'Eritrea',
    iso3166_alpha2_code: 'ER',
    is_nationality: true,
    nationality_code: 'ER',
  },
  {
    country: 'Spain',
    iso3166_alpha2_code: 'ES',
    is_nationality: true,
    nationality_code: 'ES',
  },
  {
    country: 'Ethiopia',
    iso3166_alpha2_code: 'ET',
    is_nationality: true,
    nationality_code: 'ET',
  },
  {
    country: 'Finland',
    iso3166_alpha2_code: 'FI',
    is_nationality: true,
    nationality_code: 'FI',
  },
  {
    country: 'Fiji',
    iso3166_alpha2_code: 'FJ',
    is_nationality: true,
    nationality_code: 'FJ',
  },
  {
    country: 'Falkland Islands (Malvinas)',
    iso3166_alpha2_code: 'FK',
    is_nationality: true,
    nationality_code: 'FK',
  },
  {
    country: 'Micronesia',
    iso3166_alpha2_code: 'FM',
    is_nationality: true,
    nationality_code: 'FM',
  },
  {
    country: 'Faroe Islands',
    iso3166_alpha2_code: 'FO',
    is_nationality: true,
    nationality_code: 'FO',
  },
  {
    country: 'France',
    iso3166_alpha2_code: 'FR',
    is_nationality: true,
    nationality_code: 'FR',
  },
  {
    country: 'Gabon',
    iso3166_alpha2_code: 'GA',
    is_nationality: true,
    nationality_code: 'GA',
  },
  {
    country: 'United Kingdom',
    iso3166_alpha2_code: 'GB',
    is_nationality: true,
    nationality_code: 'GB',
  },
  {
    country: 'Grenada',
    iso3166_alpha2_code: 'GD',
    is_nationality: true,
    nationality_code: 'GD',
  },
  {
    country: 'Georgia',
    iso3166_alpha2_code: 'GE',
    is_nationality: true,
    nationality_code: 'GE',
  },
  {
    country: 'French Guiana',
    iso3166_alpha2_code: 'GF',
    is_nationality: true,
    nationality_code: 'GF',
  },
  {
    country: 'Guernsey',
    iso3166_alpha2_code: 'GG',
    is_nationality: true,
    nationality_code: 'GG',
  },
  {
    country: 'Ghana',
    iso3166_alpha2_code: 'GH',
    is_nationality: true,
    nationality_code: 'GH',
  },
  {
    country: 'Gibraltar',
    iso3166_alpha2_code: 'GI',
    is_nationality: true,
    nationality_code: 'GI',
  },
  {
    country: 'Greenland',
    iso3166_alpha2_code: 'GL',
    is_nationality: true,
    nationality_code: 'GL',
  },
  {
    country: 'Gambia',
    iso3166_alpha2_code: 'GM',
    is_nationality: true,
    nationality_code: 'GM',
  },
  {
    country: 'Guinea',
    iso3166_alpha2_code: 'GN',
    is_nationality: true,
    nationality_code: 'GN',
  },
  {
    country: 'Guadeloupe',
    iso3166_alpha2_code: 'GP',
    is_nationality: true,
    nationality_code: 'GP',
  },
  {
    country: 'Equatorial Guinea',
    iso3166_alpha2_code: 'GQ',
    is_nationality: true,
    nationality_code: 'GQ',
  },
  {
    country: 'Greece',
    iso3166_alpha2_code: 'GR',
    is_nationality: true,
    nationality_code: 'GR',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    iso3166_alpha2_code: 'GS',
    is_nationality: true,
    nationality_code: 'GS',
  },
  {
    country: 'Guatemala',
    iso3166_alpha2_code: 'GT',
    is_nationality: true,
    nationality_code: 'GT',
  },
  {
    country: 'Guam',
    iso3166_alpha2_code: 'GU',
    is_nationality: true,
    nationality_code: 'GU',
  },
  {
    country: 'Guinea-Bissau',
    iso3166_alpha2_code: 'GW',
    is_nationality: true,
    nationality_code: 'GW',
  },
  {
    country: 'Guyana',
    iso3166_alpha2_code: 'GY',
    is_nationality: true,
    nationality_code: 'GY',
  },
  {
    country: 'Hong Kong',
    iso3166_alpha2_code: 'HK',
    is_nationality: true,
    nationality_code: 'HK',
  },
  {
    country: 'Heard Island and McDonald Islands',
    iso3166_alpha2_code: 'HM',
    is_nationality: true,
    nationality_code: 'HM',
  },
  {
    country: 'Honduras',
    iso3166_alpha2_code: 'HN',
    is_nationality: true,
    nationality_code: 'HN',
  },
  {
    country: 'Croatia',
    iso3166_alpha2_code: 'HR',
    is_nationality: true,
    nationality_code: 'HR',
  },
  {
    country: 'Haiti',
    iso3166_alpha2_code: 'HT',
    is_nationality: true,
    nationality_code: 'HT',
  },
  {
    country: 'Hungary',
    iso3166_alpha2_code: 'HU',
    is_nationality: true,
    nationality_code: 'HU',
  },
  {
    country: 'Indonesia',
    iso3166_alpha2_code: 'ID',
    is_nationality: true,
    nationality_code: 'ID',
  },
  {
    country: 'Ireland',
    iso3166_alpha2_code: 'IE',
    is_nationality: true,
    nationality_code: 'IE',
  },
  {
    country: 'Israel',
    iso3166_alpha2_code: 'IL',
    is_nationality: true,
    nationality_code: 'IL',
  },
  {
    country: 'Isle of Man',
    iso3166_alpha2_code: 'IM',
    is_nationality: true,
    nationality_code: 'IM',
  },
  {
    country: 'British Indian Ocean Territory',
    iso3166_alpha2_code: 'IO',
    is_nationality: true,
    nationality_code: 'IO',
  },
  {
    country: 'Iraq',
    iso3166_alpha2_code: 'IQ',
    is_nationality: true,
    nationality_code: 'IQ',
  },
  {
    country: 'Iran',
    iso3166_alpha2_code: 'IR',
    is_nationality: true,
    nationality_code: 'IR',
  },
  {
    country: 'Iceland',
    iso3166_alpha2_code: 'IS',
    is_nationality: true,
    nationality_code: 'IS',
  },
  {
    country: 'Italy',
    iso3166_alpha2_code: 'IT',
    is_nationality: true,
    nationality_code: 'IT',
  },
  {
    country: 'Jersey',
    iso3166_alpha2_code: 'JE',
    is_nationality: true,
    nationality_code: 'JE',
  },
  {
    country: 'Jamaica',
    iso3166_alpha2_code: 'JM',
    is_nationality: true,
    nationality_code: 'JM',
  },
  {
    country: 'Jordan',
    iso3166_alpha2_code: 'JO',
    is_nationality: true,
    nationality_code: 'JO',
  },
  {
    country: 'Japan',
    iso3166_alpha2_code: 'JP',
    is_nationality: true,
    nationality_code: 'JP',
  },
  {
    country: 'Kenya',
    iso3166_alpha2_code: 'KE',
    is_nationality: true,
    nationality_code: 'KE',
  },
  {
    country: 'Kyrgyzstan',
    iso3166_alpha2_code: 'KG',
    is_nationality: true,
    nationality_code: 'KG',
  },
  {
    country: 'Cambodia',
    iso3166_alpha2_code: 'KH',
    is_nationality: true,
    nationality_code: 'KH',
  },
  {
    country: 'Kiribati',
    iso3166_alpha2_code: 'KI',
    is_nationality: true,
    nationality_code: 'KI',
  },
  {
    country: 'Comoros',
    iso3166_alpha2_code: 'KM',
    is_nationality: true,
    nationality_code: 'KM',
  },
  {
    country: 'Saint Kitts and Nevis',
    iso3166_alpha2_code: 'KN',
    is_nationality: true,
    nationality_code: 'KN',
  },
  {
    country: "North Korea (Democratic People's Republic of Korea)",
    iso3166_alpha2_code: 'KP',
    is_nationality: true,
    nationality_code: 'KP',
  },
  {
    country: 'South Korea (Republic of Korea)',
    iso3166_alpha2_code: 'KR',
    is_nationality: true,
    nationality_code: 'KR',
  },
  {
    country: 'Kuwait',
    iso3166_alpha2_code: 'KW',
    is_nationality: true,
    nationality_code: 'KW',
  },
  {
    country: 'Cayman Islands',
    iso3166_alpha2_code: 'KY',
    is_nationality: true,
    nationality_code: 'KY',
  },
  {
    country: 'Kazakhstan',
    iso3166_alpha2_code: 'KZ',
    is_nationality: true,
    nationality_code: 'KZ',
  },
  {
    country: "Lao People's Democratic Republic",
    iso3166_alpha2_code: 'LA',
    is_nationality: true,
    nationality_code: 'LA',
  },
  {
    country: 'Lebanon',
    iso3166_alpha2_code: 'LB',
    is_nationality: true,
    nationality_code: 'LB',
  },
  {
    country: 'Saint Lucia',
    iso3166_alpha2_code: 'LC',
    is_nationality: true,
    nationality_code: 'LC',
  },
  {
    country: 'Liechtenstein',
    iso3166_alpha2_code: 'LI',
    is_nationality: true,
    nationality_code: 'LI',
  },
  {
    country: 'Sri Lanka',
    iso3166_alpha2_code: 'LK',
    is_nationality: true,
    nationality_code: 'LK',
  },
  {
    country: 'Liberia',
    iso3166_alpha2_code: 'LR',
    is_nationality: true,
    nationality_code: 'LR',
  },
  {
    country: 'Lesotho',
    iso3166_alpha2_code: 'LS',
    is_nationality: true,
    nationality_code: 'LS',
  },
  {
    country: 'Lithuania',
    iso3166_alpha2_code: 'LT',
    is_nationality: true,
    nationality_code: 'LT',
  },
  {
    country: 'Luxembourg',
    iso3166_alpha2_code: 'LU',
    is_nationality: true,
    nationality_code: 'LU',
  },
  {
    country: 'Latvia',
    iso3166_alpha2_code: 'LV',
    is_nationality: true,
    nationality_code: 'LV',
  },
  {
    country: 'Libya',
    iso3166_alpha2_code: 'LY',
    is_nationality: true,
    nationality_code: 'LY',
  },
  {
    country: 'Morocco',
    iso3166_alpha2_code: 'MA',
    is_nationality: true,
    nationality_code: 'MA',
  },
  {
    country: 'Monaco',
    iso3166_alpha2_code: 'MC',
    is_nationality: true,
    nationality_code: 'MC',
  },
  {
    country: 'Moldova',
    iso3166_alpha2_code: 'MD',
    is_nationality: true,
    nationality_code: 'MD',
  },
  {
    country: 'Montenegro',
    iso3166_alpha2_code: 'ME',
    is_nationality: false,
    nationality_code: '',
  },
  {
    country: 'Saint Martin - French',
    iso3166_alpha2_code: 'MF',
    is_nationality: false,
    nationality_code: 'FR',
  },
  {
    country: 'Madagascar',
    iso3166_alpha2_code: 'MG',
    is_nationality: true,
    nationality_code: 'MG',
  },
  {
    country: 'Marshall Islands',
    iso3166_alpha2_code: 'MH',
    is_nationality: true,
    nationality_code: 'MH',
  },
  {
    country: 'Macedonia',
    iso3166_alpha2_code: 'MK',
    is_nationality: true,
    nationality_code: 'MK',
  },
  {
    country: 'Mali',
    iso3166_alpha2_code: 'ML',
    is_nationality: true,
    nationality_code: 'ML',
  },
  {
    country: 'Myanmar',
    iso3166_alpha2_code: 'MM',
    is_nationality: true,
    nationality_code: 'MM',
  },
  {
    country: 'Mongolia',
    iso3166_alpha2_code: 'MN',
    is_nationality: true,
    nationality_code: 'MN',
  },
  {
    country: 'Macao',
    iso3166_alpha2_code: 'MO',
    is_nationality: true,
    nationality_code: 'MO',
  },
  {
    country: 'Northern Mariana Islands',
    iso3166_alpha2_code: 'MP',
    is_nationality: true,
    nationality_code: 'MP',
  },
  {
    country: 'Martinique',
    iso3166_alpha2_code: 'MQ',
    is_nationality: true,
    nationality_code: 'MQ',
  },
  {
    country: 'Mauritania',
    iso3166_alpha2_code: 'MR',
    is_nationality: true,
    nationality_code: 'MR',
  },
  {
    country: 'Montserrat',
    iso3166_alpha2_code: 'MS',
    is_nationality: true,
    nationality_code: 'MS',
  },
  {
    country: 'Malta',
    iso3166_alpha2_code: 'MT',
    is_nationality: true,
    nationality_code: 'MT',
  },
  {
    country: 'Mauritius',
    iso3166_alpha2_code: 'MU',
    is_nationality: true,
    nationality_code: 'MU',
  },
  {
    country: 'Maldives',
    iso3166_alpha2_code: 'MV',
    is_nationality: true,
    nationality_code: 'MV',
  },
  {
    country: 'Malawi',
    iso3166_alpha2_code: 'MW',
    is_nationality: true,
    nationality_code: 'MW',
  },
  {
    country: 'Mexico',
    iso3166_alpha2_code: 'MX',
    is_nationality: true,
    nationality_code: 'MX',
  },
  {
    country: 'Malaysia',
    iso3166_alpha2_code: 'MY',
    is_nationality: true,
    nationality_code: 'MY',
  },
  {
    country: 'Mozambique',
    iso3166_alpha2_code: 'MZ',
    is_nationality: true,
    nationality_code: 'MZ',
  },
  {
    country: 'Namibia',
    iso3166_alpha2_code: 'NA',
    is_nationality: true,
    nationality_code: 'NA',
  },
  {
    country: 'New Caledonia',
    iso3166_alpha2_code: 'NC',
    is_nationality: true,
    nationality_code: 'NC',
  },
  {
    country: 'Niger',
    iso3166_alpha2_code: 'NE',
    is_nationality: true,
    nationality_code: 'NE',
  },
  {
    country: 'Norfolk Island',
    iso3166_alpha2_code: 'NF',
    is_nationality: true,
    nationality_code: 'NF',
  },
  {
    country: 'Nigeria',
    iso3166_alpha2_code: 'NG',
    is_nationality: true,
    nationality_code: 'NG',
  },
  {
    country: 'Nicaragua',
    iso3166_alpha2_code: 'NI',
    is_nationality: true,
    nationality_code: 'NI',
  },
  {
    country: 'Netherlands',
    iso3166_alpha2_code: 'NL',
    is_nationality: true,
    nationality_code: 'NL',
  },
  {
    country: 'Norway',
    iso3166_alpha2_code: 'NO',
    is_nationality: true,
    nationality_code: 'NO',
  },
  {
    country: 'Nepal',
    iso3166_alpha2_code: 'NP',
    is_nationality: true,
    nationality_code: 'NP',
  },
  {
    country: 'Nauru',
    iso3166_alpha2_code: 'NR',
    is_nationality: true,
    nationality_code: 'NR',
  },
  {
    country: 'Niue',
    iso3166_alpha2_code: 'NU',
    is_nationality: true,
    nationality_code: 'NU',
  },
  {
    country: 'New Zealand',
    iso3166_alpha2_code: 'NZ',
    is_nationality: true,
    nationality_code: 'NZ',
  },
  {
    country: 'Oman',
    iso3166_alpha2_code: 'OM',
    is_nationality: true,
    nationality_code: 'OM',
  },
  {
    country: 'Panama',
    iso3166_alpha2_code: 'PA',
    is_nationality: true,
    nationality_code: 'PA',
  },
  {
    country: 'Peru',
    iso3166_alpha2_code: 'PE',
    is_nationality: true,
    nationality_code: 'PE',
  },
  {
    country: 'French Polynesia',
    iso3166_alpha2_code: 'PF',
    is_nationality: true,
    nationality_code: 'PF',
  },
  {
    country: 'Papua New Guinea',
    iso3166_alpha2_code: 'PG',
    is_nationality: true,
    nationality_code: 'PG',
  },
  {
    country: 'Philippines',
    iso3166_alpha2_code: 'PH',
    is_nationality: true,
    nationality_code: 'PH',
  },
  {
    country: 'Pakistan',
    iso3166_alpha2_code: 'PK',
    is_nationality: true,
    nationality_code: 'PK',
  },
  {
    country: 'Poland',
    iso3166_alpha2_code: 'PL',
    is_nationality: true,
    nationality_code: 'PL',
  },
  {
    country: 'Saint Pierre and Miquelon',
    iso3166_alpha2_code: 'PM',
    is_nationality: true,
    nationality_code: 'PM',
  },
  {
    country: 'Pitcairn',
    iso3166_alpha2_code: 'PN',
    is_nationality: true,
    nationality_code: 'PN',
  },
  {
    country: 'Puerto Rico',
    iso3166_alpha2_code: 'PR',
    is_nationality: true,
    nationality_code: 'PR',
  },
  {
    country: 'Palestine',
    iso3166_alpha2_code: 'PS',
    is_nationality: true,
    nationality_code: 'PS',
  },
  {
    country: 'Portugal',
    iso3166_alpha2_code: 'PT',
    is_nationality: true,
    nationality_code: 'PT',
  },
  {
    country: 'Palau',
    iso3166_alpha2_code: 'PW',
    is_nationality: true,
    nationality_code: 'PW',
  },
  {
    country: 'Paraguay',
    iso3166_alpha2_code: 'PY',
    is_nationality: true,
    nationality_code: 'PY',
  },
  {
    country: 'Qatar',
    iso3166_alpha2_code: 'QA',
    is_nationality: true,
    nationality_code: 'QA',
  },
  {
    country: 'Réunion',
    iso3166_alpha2_code: 'RE',
    is_nationality: true,
    nationality_code: 'RE',
  },
  {
    country: 'Romania',
    iso3166_alpha2_code: 'RO',
    is_nationality: true,
    nationality_code: 'RO',
  },
  {
    country: 'Serbia',
    iso3166_alpha2_code: 'RS',
    is_nationality: false,
    nationality_code: '',
  },
  {
    country: 'Russian Federation',
    iso3166_alpha2_code: 'RU',
    is_nationality: true,
    nationality_code: 'RU',
  },
  {
    country: 'Rwanda',
    iso3166_alpha2_code: 'RW',
    is_nationality: true,
    nationality_code: 'RW',
  },
  {
    country: 'Saudi Arabia',
    iso3166_alpha2_code: 'SA',
    is_nationality: true,
    nationality_code: 'SA',
  },
  {
    country: 'Solomon Islands',
    iso3166_alpha2_code: 'SB',
    is_nationality: true,
    nationality_code: 'SB',
  },
  {
    country: 'Seychelles',
    iso3166_alpha2_code: 'SC',
    is_nationality: true,
    nationality_code: 'SC',
  },
  {
    country: 'Sudan',
    iso3166_alpha2_code: 'SD',
    is_nationality: true,
    nationality_code: 'SD',
  },
  {
    country: 'Sweden',
    iso3166_alpha2_code: 'SE',
    is_nationality: true,
    nationality_code: 'SE',
  },
  {
    country: 'Singapore',
    iso3166_alpha2_code: 'SG',
    is_nationality: true,
    nationality_code: 'SG',
  },
  {
    country: 'Saint Helena, Ascension and Tristan da Cunha',
    iso3166_alpha2_code: 'SH',
    is_nationality: true,
    nationality_code: 'SH',
  },
  {
    country: 'Slovenia',
    iso3166_alpha2_code: 'SI',
    is_nationality: true,
    nationality_code: 'SI',
  },
  {
    country: 'Svalbard and Jan Mayen',
    iso3166_alpha2_code: 'SJ',
    is_nationality: true,
    nationality_code: 'SJ',
  },
  {
    country: 'Slovakia',
    iso3166_alpha2_code: 'SK',
    is_nationality: true,
    nationality_code: 'SK',
  },
  {
    country: 'Sierra Leone',
    iso3166_alpha2_code: 'SL',
    is_nationality: true,
    nationality_code: 'SL',
  },
  {
    country: 'San Marino',
    iso3166_alpha2_code: 'SM',
    is_nationality: true,
    nationality_code: 'SM',
  },
  {
    country: 'Senegal',
    iso3166_alpha2_code: 'SN',
    is_nationality: true,
    nationality_code: 'SN',
  },
  {
    country: 'Somalia',
    iso3166_alpha2_code: 'SO',
    is_nationality: true,
    nationality_code: 'SO',
  },
  {
    country: 'Suriname',
    iso3166_alpha2_code: 'SR',
    is_nationality: true,
    nationality_code: 'SR',
  },
  {
    country: 'South Sudan',
    iso3166_alpha2_code: 'SS',
    is_nationality: false,
    nationality_code: '',
  },
  {
    country: 'Sao Tome and Principe',
    iso3166_alpha2_code: 'ST',
    is_nationality: true,
    nationality_code: 'ST',
  },
  {
    country: 'El Salvador',
    iso3166_alpha2_code: 'SV',
    is_nationality: true,
    nationality_code: 'SV',
  },
  {
    country: 'Saint Martin - Dutch (Sint Maarten)',
    iso3166_alpha2_code: 'SX',
    is_nationality: false,
    nationality_code: 'NL',
  },
  {
    country: 'Syrian Arab Republic',
    iso3166_alpha2_code: 'SY',
    is_nationality: true,
    nationality_code: 'SY',
  },
  {
    country: 'Swaziland',
    iso3166_alpha2_code: 'SZ',
    is_nationality: true,
    nationality_code: 'SZ',
  },
  {
    country: 'Turks and Caicos Islands',
    iso3166_alpha2_code: 'TC',
    is_nationality: true,
    nationality_code: 'TC',
  },
  {
    country: 'Chad',
    iso3166_alpha2_code: 'TD',
    is_nationality: true,
    nationality_code: 'TD',
  },
  {
    country: 'French Southern Territories',
    iso3166_alpha2_code: 'TF',
    is_nationality: true,
    nationality_code: 'TF',
  },
  {
    country: 'Togo',
    iso3166_alpha2_code: 'TG',
    is_nationality: true,
    nationality_code: 'TG',
  },
  {
    country: 'Thailand',
    iso3166_alpha2_code: 'TH',
    is_nationality: true,
    nationality_code: 'TH',
  },
  {
    country: 'Tajikistan',
    iso3166_alpha2_code: 'TJ',
    is_nationality: true,
    nationality_code: 'TJ',
  },
  {
    country: 'Tokelau',
    iso3166_alpha2_code: 'TK',
    is_nationality: true,
    nationality_code: 'TK',
  },
  {
    country: 'Timor-Leste',
    iso3166_alpha2_code: 'TL',
    is_nationality: true,
    nationality_code: 'TL',
  },
  {
    country: 'Turkmenistan',
    iso3166_alpha2_code: 'TM',
    is_nationality: true,
    nationality_code: 'TM',
  },
  {
    country: 'Tunisia',
    iso3166_alpha2_code: 'TN',
    is_nationality: true,
    nationality_code: 'TN',
  },
  {
    country: 'Tonga',
    iso3166_alpha2_code: 'TO',
    is_nationality: true,
    nationality_code: 'TO',
  },
  {
    country: 'Turkey',
    iso3166_alpha2_code: 'TR',
    is_nationality: true,
    nationality_code: 'TR',
  },
  {
    country: 'Trinidad and Tobago',
    iso3166_alpha2_code: 'TT',
    is_nationality: true,
    nationality_code: 'TT',
  },
  {
    country: 'Tuvalu',
    iso3166_alpha2_code: 'TV',
    is_nationality: true,
    nationality_code: 'TV',
  },
  {
    country: 'Taiwan',
    iso3166_alpha2_code: 'TW',
    is_nationality: true,
    nationality_code: 'TW',
  },
  {
    country: 'Tanzania',
    iso3166_alpha2_code: 'TZ',
    is_nationality: true,
    nationality_code: 'TZ',
  },
  {
    country: 'Ukraine',
    iso3166_alpha2_code: 'UA',
    is_nationality: true,
    nationality_code: 'UA',
  },
  {
    country: 'Uganda',
    iso3166_alpha2_code: 'UG',
    is_nationality: true,
    nationality_code: 'UG',
  },
  {
    country: 'United States Minor Outlying Islands',
    iso3166_alpha2_code: 'UM',
    is_nationality: true,
    nationality_code: 'UM',
  },
  {
    country: 'United States',
    iso3166_alpha2_code: 'US',
    is_nationality: true,
    nationality_code: 'US',
  },
  {
    country: 'Uruguay',
    iso3166_alpha2_code: 'UY',
    is_nationality: true,
    nationality_code: 'UY',
  },
  {
    country: 'Uzbekistan',
    iso3166_alpha2_code: 'UZ',
    is_nationality: true,
    nationality_code: 'UZ',
  },
  {
    country: 'Holy See (Vatican City State)',
    iso3166_alpha2_code: 'VA',
    is_nationality: true,
    nationality_code: 'VA',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    iso3166_alpha2_code: 'VC',
    is_nationality: true,
    nationality_code: 'VC',
  },
  {
    country: 'Venezuela',
    iso3166_alpha2_code: 'VE',
    is_nationality: true,
    nationality_code: 'VE',
  },
  {
    country: 'Virgin Islands, British',
    iso3166_alpha2_code: 'VG',
    is_nationality: true,
    nationality_code: 'VG',
  },
  {
    country: 'Virgin Islands, U.S.',
    iso3166_alpha2_code: 'VI',
    is_nationality: true,
    nationality_code: 'VI',
  },
  {
    country: 'Viet Nam',
    iso3166_alpha2_code: 'VN',
    is_nationality: true,
    nationality_code: 'VN',
  },
  {
    country: 'Vanuatu',
    iso3166_alpha2_code: 'VU',
    is_nationality: true,
    nationality_code: 'VU',
  },
  {
    country: 'Wallis and Futuna',
    iso3166_alpha2_code: 'WF',
    is_nationality: true,
    nationality_code: 'WF',
  },
  {
    country: 'Samoa',
    iso3166_alpha2_code: 'WS',
    is_nationality: true,
    nationality_code: 'WS',
  },
  {
    country: 'Yemen',
    iso3166_alpha2_code: 'YE',
    is_nationality: true,
    nationality_code: 'YE',
  },
  {
    country: 'Mayotte',
    iso3166_alpha2_code: 'YT',
    is_nationality: true,
    nationality_code: 'YT',
  },
  {
    country: 'South Africa',
    iso3166_alpha2_code: 'ZA',
    is_nationality: true,
    nationality_code: 'ZA',
  },
  {
    country: 'Zambia',
    iso3166_alpha2_code: 'ZM',
    is_nationality: true,
    nationality_code: 'ZM',
  },
  {
    country: 'Zimbabwe',
    iso3166_alpha2_code: 'ZW',
    is_nationality: true,
    nationality_code: 'ZW',
  },
];

const NATIONALITIES = COUNTRIES.filter((obj) => obj.is_nationality);

export const nationalityOptions = NATIONALITIES.map((obj) => ({
  label: obj.country,
  value: obj.nationality_code,
}));

export const countryOptions = COUNTRIES.map((obj) => ({
  label: obj.country,
  value: obj.iso3166_alpha2_code,
}));

export const vaccinationStatusOptions = [
  {
    label: 'Not vaccinated',
    value: 0,
  },
  {
    label: 'Partially Vaccinated (1 dose)',
    value: 1,
  },
  {
    label: 'Fully Vaccinated (2 doses)',
    value: 2,
  },
];

export const vaccineOptions = [
  {
    value: 1,
    label: 'COVAXIN (Bharat Biotech)',
  },
  {
    value: 2,
    label: 'COVISHIELD (Serum Institute of India)',
  },
  {
    value: 3,
    label: 'Sputnik V (The Gamaleya National Center)',
  },
  {
    value: 4,
    label: 'BNT162b2 (Pfizer-BioNTech)',
  },
  {
    value: 5,
    label: 'mRNA-1273 (Moderna)',
  },
  {
    value: 6,
    label: 'Ad26.COV2.5 (Johnson & Johnson)',
  },
  {
    value: 7,
    label: 'Covovax (Serum Institute of India)',
  },
  {
    value: 8,
    label: 'ZyCoV-D (Zydus Cadila)',
  },
  {
    value: 9,
    label: 'Other / Vaccinated Outside India',
  },
  {
    value: 10,
    label: 'NVX-CoV2373 (Novavax)',
  },
  {
    value: 11,
    label: 'Covilo (SinoPharm)',
  },
  {
    value: 12,
    label: 'CoronaVac (Sinovac)',
  },
];

export const partnerCatogeries = {
  partner: 'partner',
};

export const partnerAttributes = {
  PAID_BY_GROUP: 'paid_by_group',
  PAID_BY_PATIENT: 'paid_by_patient',
  NATURE_OF_PAYMENT: {
    DECIDED_AT_ORDER_LEVEL: 'decided_at_order_level',
  },
  PAID_BY_NATURE: {
    GROUP: 'group',
  },
};

export const partnerStatusType = {
  ACTIVE: 2,
  UNACTIVE: 1,
};
